import React from "react";
import { FutureOrderList } from "../../../components/FutureOrderList";
import { FalconResupplyAutomationConfigFragment } from "../../../generated/FalconResupplyAutomationConfigFragment";

interface AutomationFutureOrdersProps {
  automation: FalconResupplyAutomationConfigFragment;
}
export const AutomationFutureOrders: React.FunctionComponent<AutomationFutureOrdersProps> = ({
  automation,
}) => {
  return (
    <FutureOrderList
      queryVariables={{ deliveryCustomer: automation.customer.id }}
    />
  );
};
