import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import { Sidebar } from "./Sidebar";
import { Breadcrumbs } from "./Breadcrumbs";

interface LayoutProps {
  title?: string;
}
export const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  title,
}) => {
  return (
    <Box display={"flex"} height={"100vh"}>
      {/*<Head>*/}
      {/*  <title>Falcon{title && ` | ${title}`}</title>*/}
      {/*</Head>*/}
      <Sidebar />
      <Box
        display={"flex"}
        flexDir={"column"}
        maxHeight={"100%"}
        overflowY={"scroll"}
        width={"100%"}
        backgroundColor={"gray.100"}
      >
        <Breadcrumbs />
        <Box flexGrow={1} overflowX={"auto"} p={8}>
          {title && <Heading mb={8}>{title}</Heading>}
          {children}
        </Box>
      </Box>
    </Box>
  );
};
