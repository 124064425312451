import { Base64 } from "js-base64";

/**
 * Converts a Relay Global ID into the raw ID it corresponds to.
 */
export function globalToPlainID(id: string): string {
  return Base64.decode(id).split(":")[1];
}

/**
 * Converts a raw ID into a Relay Global ID of the specified object type
 */
export function plainToGlobalID(id: string, type: string): string {
  return Base64.encode(`${type}:${id}`);
}

/**
 * Returns the type of the global ID
 */
export function getGlobalIDType(id: string): string {
  return Base64.decode(id).split(":")[0];
}

/**
 * Rebuilds a Relay Global ID with the desired object type without changing the
 * ID
 */
export function castGlobalId(id: string, type: string): string {
  return plainToGlobalID(globalToPlainID(id), type);
}
