import React from "react";
import { useQuery } from "@apollo/client";
import { ListLogEntry, ListLogEntryVariables } from "../generated/ListLogEntry";
import { ListLogEntryQuery } from "../api/queries";
import { flattenEdges } from "../utils/edges";
import { Panel } from "./Panel";
import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { formatDateMonthYearHourMinute } from "../utils/datetime";

interface LogEntryListProps {
  contentType: string;
  objectId: number;
}

export const LogEntryList: React.FC<LogEntryListProps> = ({
  contentType,
  objectId,
}) => {
  const { data } = useQuery<ListLogEntry, ListLogEntryVariables>(
    ListLogEntryQuery,
    {
      variables: {
        contentType: contentType,
        objectId: objectId,
      },
      pollInterval: 8000,
    }
  );
  const flattened = flattenEdges(data?.listLogEntry);
  if (flattened.length === 0) {
    return (
      <Panel mb={5}>
        <Text>No logs found</Text>
      </Panel>
    );
  }
  return (
    <Panel mb={5}>
      <Table>
        <Thead>
          <Tr>
            <Th>Message</Th>
            <Th>Level</Th>
            <Th>Time</Th>
          </Tr>
        </Thead>
        <Tbody>
          {flattened.map((entry) => (
            <Tr key={entry.id}>
              <Td>{entry.message}</Td>
              <Td>{entry.level}</Td>
              <Td>{formatDateMonthYearHourMinute(entry.datetimeCreated)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Panel>
  );
};
