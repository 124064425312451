import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@apollo/client";
import {
  LoginUnverified,
  LoginUnverifiedVariables,
} from "../generated/LoginUnverified";
import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { Panel } from "../components/Panel";
import { CurrentUserFragment } from "../generated/CurrentUserFragment";
import { GetcurrentUserQuery } from "../api/queries";
import { GetCurrentUser } from "../generated/GetCurrentUser";
import { Session } from "../api/auth";
import { observer } from "mobx-react-lite";
import { NAV_BG_INACTIVE, NAV_TEXT_INACTIVE } from "../theme";

const LOGIN_UNVERIFIED = gql`
  mutation LoginUnverified($email: String!, $password: String!) {
    loginUnverified(input: { email: $email, password: $password }) {
      authToken
    }
  }
`;

export const LoginProvider: React.FC = observer(({ children }) => {
  const [
    loginUnverified,
    { loading: loginLoading, error: loginError },
  ] = useMutation<LoginUnverified, LoginUnverifiedVariables>(LOGIN_UNVERIFIED, {
    errorPolicy: "all",
  });
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: refetchUser,
  } = useQuery<GetCurrentUser>(GetcurrentUserQuery, {
    fetchPolicy: "network-only",
  });

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [currentUser, setCurrentUser] = useState<
    CurrentUserFragment | undefined
  >(undefined);

  const doLogin = () => {
    loginUnverified({ variables: { email: email, password: password } }).then(
      (result) => {
        Session.setToken(result.data?.loginUnverified?.authToken || null);
      }
    );
  };

  const token = Session.token;
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    refetchUser();
  }, [token, refetchUser]);

  useEffect(() => {
    if (userData && userData.getCurrentUser) {
      setCurrentUser(userData.getCurrentUser);
    }
  }, [userData, setCurrentUser]);

  const loading = loginLoading || userLoading;
  const error = loginError || userError;

  const errorMessage = React.useMemo<string | undefined>(() => {
    if (loginError) {
      return loginError.message;
    }
    if (userError) {
      return userError.message;
    }
  }, [loginError, userError]);

  if (userLoading) {
    return (
      <Box pt={"23vh"} backgroundColor={"gray.100"} height={"100vh"}>
        <Center>
          <Spinner size={"xl"} margin={"0 auto"} />
        </Center>
      </Box>
    );
  }

  // TODO: Support submit via enter key
  if (!(currentUser && currentUser.isSignedIn)) {
    return (
      <Box pt={"23vh"} backgroundColor={"gray.100"} height={"100vh"}>
        <Panel
          maxWidth={"400px"}
          margin={"0 auto"}
          backgroundColor={"#ffffff"}
          p={0}
        >
          <Box
            mb={5}
            height={"74px"}
            backgroundColor={NAV_BG_INACTIVE}
            color={NAV_TEXT_INACTIVE}
            roundedTop={"md"}
          >
            <Flex
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Heading>Food Falcon</Heading>
            </Flex>
          </Box>
          {errorMessage ? (
            <Alert status="error">
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          ) : null}
          <Box p={6}>
            <Box mb={3}>
              <Text mb={1}>Email</Text>
              <Input
                placeholder={"Email"}
                disabled={loading}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!error}
              />
            </Box>
            <Box mb={3}>
              <Text mb={1}>Password</Text>
              <Input
                placeholder={"Password"}
                disabled={loading}
                type={"password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isInvalid={!!error}
              />
            </Box>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
            >
              <Button
                onClick={doLogin}
                disabled={!email || !password}
                isLoading={loading}
                loadingText={"Authenticating"}
                colorScheme={"blue"}
              >
                Log In
              </Button>
            </Flex>
          </Box>
        </Panel>
      </Box>
    );
  } else {
    return <>{children}</>;
  }
});
