import { Box, Button, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { Panel } from "./Panel";
import React from "react";
import { FalconCustomerFragment } from "../generated/FalconCustomerFragment";
import { URL_LOCATION_FUTURE, URL_LOCATION_ORDERS } from "../utils/urls";
import { Link } from "react-router-dom";

export enum CustomerPageName {
  OVERVIEW = "OVERVIEW",
  ORDERS = "ORDERS",
  FUTURE = "FUTURE",
  USAGE = "USAGE",
  SETTINGS = "SETTINGS",
}

interface CustomerLayoutProps {
  customer: FalconCustomerFragment;
  activePage: CustomerPageName;
}
export const CustomerLayout: React.FunctionComponent<CustomerLayoutProps> = ({
  customer,
  activePage,
  children,
}) => {
  return (
    <Box>
      <Heading mb={8}>{customer.name}</Heading>
      <SimpleGrid columns={3} mb={5} spacing={5}>
        <Panel>
          <Text>{customer.address}</Text>
          <Text>
            {customer.postalCode} {customer.city}
          </Text>
          <Text casing={"capitalize"}>{customer.country}</Text>
        </Panel>
        <Panel>
          <Text>{customer.contactPerson}</Text>
          <Text>{customer.email}</Text>
          <Text>{customer.phone}</Text>
        </Panel>
        <Panel>
          <Text>Customer Code</Text>
          <Text>{customer.customerCode}</Text>
        </Panel>
      </SimpleGrid>
      <Panel p={2} mb={5}>
        <Link to={URL_LOCATION_FUTURE.builder(customer.id)}>
          <Button mx={2} isActive={activePage === CustomerPageName.FUTURE}>
            Pending Orders
          </Button>
        </Link>
        <Link to={URL_LOCATION_ORDERS.builder(customer.id)}>
          <Button mx={2} isActive={activePage === CustomerPageName.ORDERS}>
            Delivered Orders
          </Button>
        </Link>
        {/*<Link to={URL_LOCATION_OVERVIEW.builder(customer.id)}>*/}
        {/*  <Button mx={2} isActive={activePage === CustomerPageName.OVERVIEW}>*/}
        {/*    Overview*/}
        {/*  </Button>*/}
        {/*</Link>*/}
        {/*<Link to={URL_LOCATION_USAGE.builder(customer.id)}>*/}
        {/*  <Button mx={2} isActive={activePage === CustomerPageName.USAGE}>*/}
        {/*    Usage*/}
        {/*  </Button>*/}
        {/*</Link>*/}
        {/*<Link to={URL_LOCATION_SETTINGS.builder(customer.id)}>*/}
        {/*  <Button mx={2} isActive={activePage === CustomerPageName.SETTINGS}>*/}
        {/*    Settings*/}
        {/*  </Button>*/}
        {/*</Link>*/}
      </Panel>
      {children}
    </Box>
  );
};
