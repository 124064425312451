/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum DLPrimeIntegrationLogOrdering {
  RUNNER_VERSION_ASC = "RUNNER_VERSION_ASC",
  RUNNER_VERSION_DESC = "RUNNER_VERSION_DESC",
  START_TIME_ASC = "START_TIME_ASC",
  START_TIME_DESC = "START_TIME_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
  STOP_TIME_ASC = "STOP_TIME_ASC",
  STOP_TIME_DESC = "STOP_TIME_DESC",
}

/**
 * An enumeration.
 */
export enum DLPrimeIntegrationLogStatus {
  ERROR = "ERROR",
  PENDING = "PENDING",
  STARTED = "STARTED",
  SUCCESS = "SUCCESS",
}

/**
 * An enumeration.
 */
export enum DLPrimeOrderOrdering {
  CUSTOMER_CODE_ID_ASC = "CUSTOMER_CODE_ID_ASC",
  CUSTOMER_CODE_ID_DESC = "CUSTOMER_CODE_ID_DESC",
  ORDER_DATE_ASC = "ORDER_DATE_ASC",
  ORDER_DATE_DESC = "ORDER_DATE_DESC",
  ORDER_NO_ASC = "ORDER_NO_ASC",
  ORDER_NO_DESC = "ORDER_NO_DESC",
}

/**
 * An enumeration.
 */
export enum FalconCustomerOrdering {
  CITY_ASC = "CITY_ASC",
  CITY_DESC = "CITY_DESC",
  CUSTOMER_CODE_ASC = "CUSTOMER_CODE_ASC",
  CUSTOMER_CODE_DESC = "CUSTOMER_CODE_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  POSTAL_CODE_ASC = "POSTAL_CODE_ASC",
  POSTAL_CODE_DESC = "POSTAL_CODE_DESC",
}

/**
 * An enumeration.
 */
export enum FalconFutureOrderCustomerConfirmationStatus {
  CONFIRMED = "CONFIRMED",
  CUSTOM_REQUEST = "CUSTOM_REQUEST",
  EXPIRED = "EXPIRED",
  REJECTED = "REJECTED",
  REQUEST_FAILURE = "REQUEST_FAILURE",
  REQUEST_NOT_SENT = "REQUEST_NOT_SENT",
  REQUEST_SENT = "REQUEST_SENT",
}

/**
 * An enumeration.
 */
export enum FalconFutureOrderFoodduckReviewStatus {
  APPROVED = "APPROVED",
  EXPIRED = "EXPIRED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

/**
 * An enumeration.
 */
export enum FalconFutureOrderOrderHandlingStatus {
  ORDER_CREATED = "ORDER_CREATED",
  UNHANDLED = "UNHANDLED",
}

/**
 * An enumeration.
 */
export enum FalconFutureOrderOrdering {
  BILLING_CUSTOMER__CUSTOMER_CODE_ASC = "BILLING_CUSTOMER__CUSTOMER_CODE_ASC",
  BILLING_CUSTOMER__CUSTOMER_CODE_DESC = "BILLING_CUSTOMER__CUSTOMER_CODE_DESC",
  BILLING_CUSTOMER__NAME_ASC = "BILLING_CUSTOMER__NAME_ASC",
  BILLING_CUSTOMER__NAME_DESC = "BILLING_CUSTOMER__NAME_DESC",
  DELIVERY_CUSTOMER__CUSTOMER_CODE_ASC = "DELIVERY_CUSTOMER__CUSTOMER_CODE_ASC",
  DELIVERY_CUSTOMER__CUSTOMER_CODE_DESC = "DELIVERY_CUSTOMER__CUSTOMER_CODE_DESC",
  DELIVERY_CUSTOMER__POSTAL_CODE_ASC = "DELIVERY_CUSTOMER__POSTAL_CODE_ASC",
  DELIVERY_CUSTOMER__POSTAL_CODE_DESC = "DELIVERY_CUSTOMER__POSTAL_CODE_DESC",
  EXPIRY_DATE_ASC = "EXPIRY_DATE_ASC",
  EXPIRY_DATE_DESC = "EXPIRY_DATE_DESC",
  SHIP_DATE_ASC = "SHIP_DATE_ASC",
  SHIP_DATE_DESC = "SHIP_DATE_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
}

/**
 * An enumeration.
 */
export enum FalconFutureOrderStatus {
  CUSTOMER_CONFIRMATION = "CUSTOMER_CONFIRMATION",
  FINISHED_FAILURE = "FINISHED_FAILURE",
  FINISHED_SUCCESS = "FINISHED_SUCCESS",
  FOODDUCK_CONFIRMATION = "FOODDUCK_CONFIRMATION",
  ORDER_HANDLING = "ORDER_HANDLING",
}

/**
 * An enumeration.
 */
export enum FalconOrderImportLogOrdering {
  RUNNER_VERSION_ASC = "RUNNER_VERSION_ASC",
  RUNNER_VERSION_DESC = "RUNNER_VERSION_DESC",
  START_TIME_ASC = "START_TIME_ASC",
  START_TIME_DESC = "START_TIME_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
  STOP_TIME_ASC = "STOP_TIME_ASC",
  STOP_TIME_DESC = "STOP_TIME_DESC",
}

/**
 * An enumeration.
 */
export enum FalconOrderImportLogStatus {
  ERROR = "ERROR",
  PENDING = "PENDING",
  STARTED = "STARTED",
  SUCCESS = "SUCCESS",
}

/**
 * An enumeration.
 */
export enum FalconOrderOrdering {
  CUSTOMER__CUSTOMER_CODE_ASC = "CUSTOMER__CUSTOMER_CODE_ASC",
  CUSTOMER__CUSTOMER_CODE_DESC = "CUSTOMER__CUSTOMER_CODE_DESC",
  CUSTOMER__NAME_ASC = "CUSTOMER__NAME_ASC",
  CUSTOMER__NAME_DESC = "CUSTOMER__NAME_DESC",
  DLPRIME_ORDER_NUMBER_ASC = "DLPRIME_ORDER_NUMBER_ASC",
  DLPRIME_ORDER_NUMBER_DESC = "DLPRIME_ORDER_NUMBER_DESC",
  ORDER_DATE_ASC = "ORDER_DATE_ASC",
  ORDER_DATE_DESC = "ORDER_DATE_DESC",
  PRICE_TOTAL_ASC = "PRICE_TOTAL_ASC",
  PRICE_TOTAL_DESC = "PRICE_TOTAL_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
}

/**
 * An enumeration.
 */
export enum FalconOrderStatus {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
}

/**
 * An enumeration.
 */
export enum FalconProductOrdering {
  CONTENT_WEIGHT_GRAMS_ASC = "CONTENT_WEIGHT_GRAMS_ASC",
  CONTENT_WEIGHT_GRAMS_DESC = "CONTENT_WEIGHT_GRAMS_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  PRODUCT_CODE_ASC = "PRODUCT_CODE_ASC",
  PRODUCT_CODE_DESC = "PRODUCT_CODE_DESC",
  PRODUCT_TYPE_ASC = "PRODUCT_TYPE_ASC",
  PRODUCT_TYPE_DESC = "PRODUCT_TYPE_DESC",
}

/**
 * An enumeration.
 */
export enum FalconProductProductType {
  BUTTER = "BUTTER",
  UNKNOWN = "UNKNOWN",
}

/**
 * An enumeration.
 */
export enum LocationOrdering {
  CITY_ASC = "CITY_ASC",
  CITY_DESC = "CITY_DESC",
  CUSTOMER__CUSTOMER_NUMBER_ASC = "CUSTOMER__CUSTOMER_NUMBER_ASC",
  CUSTOMER__CUSTOMER_NUMBER_DESC = "CUSTOMER__CUSTOMER_NUMBER_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  POSTAL_CODE_ASC = "POSTAL_CODE_ASC",
  POSTAL_CODE_DESC = "POSTAL_CODE_DESC",
}

/**
 * An enumeration.
 */
export enum LogEntryLevel {
  ALERT = "ALERT",
  ERROR = "ERROR",
  INFO = "INFO",
  WARNING = "WARNING",
}

/**
 * An enumeration.
 */
export enum MyFoodduckIntegrationLogOrdering {
  RUNNER_VERSION_ASC = "RUNNER_VERSION_ASC",
  RUNNER_VERSION_DESC = "RUNNER_VERSION_DESC",
  START_TIME_ASC = "START_TIME_ASC",
  START_TIME_DESC = "START_TIME_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
  STOP_TIME_ASC = "STOP_TIME_ASC",
  STOP_TIME_DESC = "STOP_TIME_DESC",
}

/**
 * An enumeration.
 */
export enum MyFoodduckIntegrationLogStatus {
  ERROR = "ERROR",
  PENDING = "PENDING",
  STARTED = "STARTED",
  SUCCESS = "SUCCESS",
}

/**
 * An enumeration.
 */
export enum NodeDailyUsageReportOrdering {
  DATE_ASC = "DATE_ASC",
  DATE_DESC = "DATE_DESC",
  PORTIONS_ASC = "PORTIONS_ASC",
  PORTIONS_DESC = "PORTIONS_DESC",
  REFILLS_ASC = "REFILLS_ASC",
  REFILLS_DESC = "REFILLS_DESC",
}

/**
 * An enumeration.
 */
export enum NodeOrdering {
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  SERIAL_NUMBER_ASC = "SERIAL_NUMBER_ASC",
  SERIAL_NUMBER_DESC = "SERIAL_NUMBER_DESC",
}

/**
 * An enumeration.
 */
export enum TwilioMessageTwilioDirection {
  INBOUND = "INBOUND",
  OUTBOUND_API = "OUTBOUND_API",
  OUTBOUND_CALL = "OUTBOUND_CALL",
  OUTBOUND_REPLY = "OUTBOUND_REPLY",
}

/**
 * An enumeration.
 */
export enum UserOrdering {
  EMAIL_ASC = "EMAIL_ASC",
  EMAIL_DESC = "EMAIL_DESC",
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
