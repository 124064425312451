import { gql } from "@apollo/client/core";

const PageInfoFragment = gql`
  fragment PageInfoFragment on PageInfo {
    endCursor
    hasNextPage
  }
`;

const FalconCustomerFragment = gql`
  fragment FalconCustomerFragment on FalconCustomerAutoNode {
    address
    city
    contactPerson
    country
    customerCode
    datetimeCreated
    datetimeUpdated
    email
    id
    name
    phone
    postalCode
    dlprimeCustomer {
      id
    }
  }
`;

export const ListFalconCustomersQuery = gql`
  query ListFalconCustomers(
    $cursor: String
    $count: Int
    $query: String
    $ordering: FalconCustomerOrdering
  ) {
    listFalconCustomer(
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
    ) {
      edges {
        node {
          ...FalconCustomerFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${FalconCustomerFragment}
  ${PageInfoFragment}
`;

export const FalconCustomerQuery = gql`
  query GetFalconCustomer($id: ID!) {
    getFalconCustomer(id: $id) {
      ...FalconCustomerFragment
    }
  }

  ${FalconCustomerFragment}
`;

export const ListDeliveryFalconCustomersQuery = gql`
  query ListDeliveryFalconCustomers(
    $cursor: String
    $count: Int
    $query: String
    $ordering: FalconCustomerOrdering
  ) {
    listDeliveryFalconCustomer(
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
    ) {
      edges {
        node {
          ...FalconCustomerFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${FalconCustomerFragment}
  ${PageInfoFragment}
`;

const DLPrimeOrderLineFragment = gql`
  fragment DLPrimeOrderLineFragment on DLPrimeOrderLineAutoNode {
    boNto
    boQty
    boTax
    boTotal
    comment
    description
    discount
    discountAmount
    id
    invQty
    lineNo
    localDatetimeCreated
    localDatetimeUpdated
    localId
    lotInfo
    nto
    offerNo
    price
    priceTax
    prodCode
    qty
    rowType
    shipDate
    shipDateActual
    tax
    total
    unitPriceDiscountAmount
    userType
    userTypeDescription
    warehouse
    warehouseAddress1
    warehouseAddress2
    warehouseAddress3
    warehouseCity
    warehouseCountryCode
    warehouseDescription
    warehouseFax
    warehouseTelephone
    warehouseZip
    workNo
  }
`;

const DLPrimeOrderFragment = gql`
  fragment DLPrimeOrderFragment on DLPrimeOrderAutoNode {
    amount
    buyersReference
    comment
    contactName
    currency
    customerCode {
      id
    }
    discAmount
    extended1
    extended2
    extended3
    id
    lines {
      edges {
        node {
          ...DLPrimeOrderLineFragment
        }
      }
    }
    localDatetimeCreated
    localDatetimeUpdated
    localId
    msgEmail
    msgGsm
    orderDate
    orderNo
    parcelCodes
    reference
    salesReference
    salespersonName
    shipDate
    shiptermDescription
    shipwayDescription
    status
    taxAmount
    termCode
    termCodeDescription
    totAmount
    userType
    userTypeDescription
  }

  ${DLPrimeOrderLineFragment}
`;

export const ListDLPrimeOrdersQuery = gql`
  query ListDLPrimeOrders(
    $customerCode: ID
    $cursor: String
    $count: Int
    $query: String
    $ordering: DLPrimeOrderOrdering
  ) {
    listDLPrimeOrder(
      customerCode: $customerCode
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
    ) {
      edges {
        node {
          ...DLPrimeOrderFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${DLPrimeOrderFragment}
  ${PageInfoFragment}
`;

export const FalconProductFragment = gql`
  fragment FalconProductFragment on FalconProductAutoNode {
    id
    productCode
    description
    unitPrice
    productType
    contentWeightGrams
    datetimeCreated
    datetimeUpdated
  }
`;

export const ListFalconProductsQuery = gql`
  query ListFalconProducts(
    $cursor: String
    $count: Int
    $query: String
    $ordering: FalconProductOrdering
  ) {
    listFalconProduct(
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
    ) {
      edges {
        node {
          ...FalconProductFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${FalconProductFragment}
  ${PageInfoFragment}
`;

export const FalconOrderLineFragment = gql`
  fragment FalconOrderLineFragment on FalconOrderLineAutoNode {
    id
    product {
      ...FalconProductFragment
    }
    order {
      id
    }
    lineNumber
    quantity
    comment
    description
    unitPrice
    price
    priceDiscount
    priceTax
    priceTotal
  }

  ${FalconProductFragment}
`;

export const FalconOrderFragment = gql`
  fragment FalconOrderFragment on FalconOrderAutoNode {
    buyersReference
    contactEmail
    contactPhoneNumber
    customer {
      id
      name
      customerCode
    }
    datetimeCreated
    datetimeUpdated
    dlprimeOrderNumber
    id
    orderDate
    price
    priceCurrency
    priceDiscount
    priceTax
    priceTotal
    salesReference
    salespersonName
    shipDate
    shiptermDescription
    shipwayDescription
    status
    termCode
    termCodeDescription
    userType
    userTypeDescription
    lines {
      edges {
        node {
          ...FalconOrderLineFragment
        }
      }
    }
  }

  ${FalconOrderLineFragment}
`;

export const ListFalconOrdersQuery = gql`
  query ListFalconOrders(
    $deliveryCustomerId: ID
    $cursor: String
    $count: Int
    $query: String
    $ordering: FalconOrderOrdering
  ) {
    listFalconOrder(
      deliveryCustomerId: $deliveryCustomerId
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
    ) {
      edges {
        node {
          ...FalconOrderFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${FalconOrderFragment}
  ${PageInfoFragment}
`;

export const UserFragment = gql`
  fragment UserFragment on UserAutoNode {
    dateJoined
    email
    firstName
    id
    isActive
    isStaff
    isSuperuser
    lastLogin
    lastName
    username
  }
`;

export const ListUsersQuery = gql`
  query ListUsers(
    $cursor: String
    $count: Int
    $query: String
    $ordering: UserOrdering
  ) {
    listUser(
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
    ) {
      edges {
        node {
          ...UserFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${UserFragment}
  ${PageInfoFragment}
`;

export const DLPrimeIntegrationLogFragment = gql`
  fragment DLPrimeIntegrationLogFragment on DLPrimeIntegrationLogAutoNode {
    datetimeCreated
    datetimeUpdated
    id
    log
    runnerVersion
    startTime
    status
    stopTime
  }
`;

export const ListDLPrimeIntegrationLogsQuery = gql`
  query ListDLPrimeIntegrationLogs(
    $cursor: String
    $count: Int
    $query: String
    $ordering: DLPrimeIntegrationLogOrdering
  ) {
    listDLPrimeIntegrationLog(
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
    ) {
      edges {
        node {
          ...DLPrimeIntegrationLogFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${DLPrimeIntegrationLogFragment}
  ${PageInfoFragment}
`;

export const MyFoodduckIntegrationLogFragment = gql`
  fragment MyFoodduckIntegrationLogFragment on MyFoodduckIntegrationLogAutoNode {
    datetimeCreated
    datetimeUpdated
    id
    log
    runnerVersion
    startTime
    status
    stopTime
  }
`;

export const ListMyFoodduckIntegrationLogsQuery = gql`
  query ListMyFoodduckIntegrationLogs(
    $cursor: String
    $count: Int
    $query: String
    $ordering: MyFoodduckIntegrationLogOrdering
  ) {
    listMyFoodduckIntegrationLog(
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
    ) {
      edges {
        node {
          ...MyFoodduckIntegrationLogFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${MyFoodduckIntegrationLogFragment}
  ${PageInfoFragment}
`;

export const FalconOrderImportLogFragment = gql`
  fragment FalconOrderImportLogFragment on FalconOrderImportLogAutoNode {
    datetimeCreated
    datetimeUpdated
    id
    log
    runnerVersion
    startTime
    status
    stopTime
  }
`;

export const ListFalconOrderImportLogsQuery = gql`
  query ListFalconOrderImportLogs(
    $cursor: String
    $count: Int
    $query: String
    $ordering: FalconOrderImportLogOrdering
  ) {
    listFalconOrderImportLog(
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
    ) {
      edges {
        node {
          ...FalconOrderImportLogFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${FalconOrderImportLogFragment}
  ${PageInfoFragment}
`;

export const NodeDailyUsageReportFragment = gql`
  fragment NodeDailyUsageReportFragment on NodeDailyUsageReportAutoNode {
    id
    date
    localDatetimeCreated
    localDatetimeUpdated
    localId
    node {
      id
    }
    portions
    refills
  }
`;

export const ListNodeDailyUsageReportsQuery = gql`
  query ListNodeDailyUsageReports(
    $node: ID
    $cursor: String
    $count: Int
    $query: String
    $ordering: NodeDailyUsageReportOrdering
  ) {
    listNodeDailyUsageReport(
      node: $node
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
    ) {
      edges {
        node {
          ...NodeDailyUsageReportFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${NodeDailyUsageReportFragment}
  ${PageInfoFragment}
`;

export const NodeFragment = gql`
  fragment NodeFragment on NodeAutoNode {
    id
    Id
    condition
    coords
    customer {
      id
    }
    customerCity
    customerName
    customerServiceSize
    dataAlertDefer
    dataAlertDeferActive
    deliveryWeek
    deploymentDate
    freetext
    highestTicketPriority
    inactiveData
    inoiFwVer
    lastStatus
    latestTicket
    localDatetimeCreated
    localDatetimeUpdated
    localId
    location {
      id
    }
    locationCity
    locationName
    locationServiceSize
    manufacturing
    mobileDongle
    name
    newTicket
    options
    productType
    returnable
    serialNumber
    serviceSize
    state
    ticketCount
  }
`;

export const ListNodesQuery = gql`
  query ListNodes(
    $location: ID
    $cursor: String
    $count: Int
    $query: String
    $ordering: NodeOrdering
  ) {
    listNode(
      location: $location
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
    ) {
      edges {
        node {
          ...NodeFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${NodeFragment}
  ${PageInfoFragment}
`;

export const LocationFragment = gql`
  fragment LocationFragment on LocationAutoNode {
    id
    Id
    address
    city
    contactPerson
    country
    customerName
    dailyDinerCount
    freetext
    hyperlink
    localDatetimeCreated
    localDatetimeUpdated
    localId
    location
    locationNumber
    name
    nodeCount
    openPerWeek
    phone
    postalCode
    serviceSize
    sittingsPerDay
  }
`;

export const ListLocationsQuery = gql`
  query ListLocations(
    $locationNumber: String
    $cursor: String
    $count: Int
    $query: String
    $ordering: LocationOrdering
  ) {
    listLocation(
      locationNumber: $locationNumber
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
    ) {
      edges {
        node {
          ...LocationFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${LocationFragment}
  ${PageInfoFragment}
`;

export const FalconFutureOrderLineFragment = gql`
  fragment FalconFutureOrderLineFragment on FalconFutureOrderLineAutoNode {
    datetimeCreated
    datetimeUpdated
    id
    lineNumber
    order {
      id
    }
    product {
      ...FalconProductFragment
    }
    quantity
  }
  ${FalconProductFragment}
`;

export const FalconFutureOrderFragment = gql`
  fragment FalconFutureOrderFragment on FalconFutureOrderAutoNode {
    id
    isArchived
    isUnhandled
    twilioTopic {
      id
    }
    billingCustomer {
      id
      name
      customerCode
      address
      postalCode
      city
      country
    }
    customer {
      id
    }
    datetimeCreated
    datetimeUpdated
    deliveryCustomer {
      id
      name
      customerCode
      address
      postalCode
      city
      country
      phone
    }
    lines {
      edges {
        node {
          ...FalconFutureOrderLineFragment
        }
      }
    }
    shipDate
    expiryDate
    status
    confirmationPhoneNumber
    foodduckReviewStatus
    customerConfirmationStatus
    orderHandlingStatus
  }
  ${FalconFutureOrderLineFragment}
`;

export const ListFalconFutureOrdersQuery = gql`
  query ListFalconFutureOrders(
    $deliveryCustomer: ID
    $customer: ID
    $cursor: String
    $count: Int
    $query: String
    $ordering: FalconFutureOrderOrdering
    $isArchived: Boolean
    $isUnhandled: Boolean
  ) {
    listFalconFutureOrder(
      customer: $customer
      deliveryCustomer: $deliveryCustomer
      after: $cursor
      first: $count
      query: $query
      ordering: $ordering
      isArchived: $isArchived
      isUnhandled: $isUnhandled
    ) {
      edges {
        node {
          ...FalconFutureOrderFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${FalconFutureOrderFragment}
  ${PageInfoFragment}
`;

export const GetFalconFutureOrderQuery = gql`
  query GetFalconFutureOrder($id: ID!) {
    getFalconFutureOrder(id: $id) {
      ...FalconFutureOrderFragment
    }
  }
  ${FalconFutureOrderFragment}
`;

export const CurrentUserFragment = gql`
  fragment CurrentUserFragment on CurrentUser {
    firstName
    lastName
    email
    isSignedIn
    isStaff
  }
`;

export const GetcurrentUserQuery = gql`
  query GetCurrentUser {
    getCurrentUser {
      ...CurrentUserFragment
    }
  }
  ${CurrentUserFragment}
`;

export const LogEntryFragment = gql`
  fragment LogEntryFragment on LogEntryAutoNode {
    id
    needsAttention
    datetimeCreated
    message
    level
  }
`;

export const ListLogEntryQuery = gql`
  query ListLogEntry($contentType: ID!, $objectId: Int!) {
    listLogEntry(linkedObjContentType: $contentType, linkedObjId: $objectId) {
      edges {
        node {
          ...LogEntryFragment
        }
      }
    }
  }
  ${LogEntryFragment}
`;

export const ContentTypesFragment = gql`
  fragment ContentTypesFragment on ContentTypes {
    falconFutureOrder
    falconResupplyAutomationConfig
  }
`;

export const ContentTypeQuery = gql`
  query GetContentTypes {
    contentTypes {
      ...ContentTypesFragment
    }
  }
  ${ContentTypesFragment}
`;

const TwilioMessageFragment = gql`
  fragment TwilioMessageFragment on TwilioMessageAutoNode {
    id
    twilioDirection
    twilioFrom
    twilioTo
    twilioBody
    datetimeHandled
  }
`;

export const ListTwilioMessageQuery = gql`
  query ListTwilioMessage($topic: ID!) {
    listTwilioMessage(topic: $topic) {
      edges {
        node {
          ...TwilioMessageFragment
        }
      }
    }
  }
  ${TwilioMessageFragment}
`;

const FalconResupplyAutomationConfigFragment = gql`
  fragment FalconResupplyAutomationConfigFragment on FalconResupplyAutomationConfigAutoNode {
    id
    confirmationPhoneNumber
    customer {
      ...FalconCustomerFragment
    }
    billingCustomer {
      ...FalconCustomerFragment
    }
  }
  ${FalconCustomerFragment}
`;

export const ListFalconResupplyAutomationConfigQuery = gql`
  query ListFalconResupplyAutomationConfig($enabled: Boolean) {
    listFalconResupplyAutomationConfig(enabled: $enabled) {
      edges {
        node {
          ...FalconResupplyAutomationConfigFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${FalconResupplyAutomationConfigFragment}
  ${PageInfoFragment}
`;

export const GetFalconResupplyAutomationConfigQuery = gql`
  query GetFalconResupplyAutomationConfig($id: ID!) {
    getFalconResupplyAutomationConfig(id: $id) {
      ...FalconResupplyAutomationConfigFragment
    }
  }
  ${FalconResupplyAutomationConfigFragment}
`;
