import { gql } from "@apollo/client/core";
import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "@chakra-ui/react";
import {
  ScheduledOrderToggleArchived,
  ScheduledOrderToggleArchivedVariables,
} from "../../generated/ScheduledOrderToggleArchived";
import { BiArchiveIn, BiArchiveOut } from "react-icons/all";
import { FalconFutureOrderFragment } from "../../generated/FalconFutureOrderFragment";
import { FalconFutureOrderStatus } from "../../generated/globalTypes";
import { ButtonProps } from "@chakra-ui/button/dist/types/button";
import { ListLogEntryQuery } from "../../api/queries";

const TOGGLE_ARCHIVED = gql`
  mutation ScheduledOrderToggleArchived(
    $orderId: GlobalIDInput!
    $isArchived: Boolean!
  ) {
    scheduledOrderToggleArchived(
      input: { orderId: $orderId, isArchived: $isArchived }
    ) {
      success
    }
  }
`;

function canToggleArchived(order: FalconFutureOrderFragment): boolean {
  return (
    order.status === FalconFutureOrderStatus.FINISHED_FAILURE ||
    order.status === FalconFutureOrderStatus.FINISHED_SUCCESS ||
    order.isArchived
  );
}

interface ArchiveButtonProps extends ButtonProps {
  orderData: FalconFutureOrderFragment;
  callback: () => void;
}
export const ArchiveButton: React.FC<ArchiveButtonProps> = ({
  orderData,
  callback,
  ...props
}) => {
  const [mutateToggleArchived] = useMutation<
    ScheduledOrderToggleArchived,
    ScheduledOrderToggleArchivedVariables
  >(TOGGLE_ARCHIVED, {
    errorPolicy: "all",
    refetchQueries: [ListLogEntryQuery],
  });

  const toggleArchived = (isArchived: boolean) => {
    if (!canToggleArchived(orderData)) return;
    mutateToggleArchived({
      variables: {
        orderId: orderData.id,
        isArchived: isArchived,
      },
    }).finally(() => {
      callback();
    });
  };

  const Icon = orderData.isArchived ? <BiArchiveOut /> : <BiArchiveIn />;

  return (
    <Button
      leftIcon={Icon}
      colorScheme={"gray"}
      onClick={() => toggleArchived(!orderData.isArchived)}
      disabled={!canToggleArchived(orderData)}
      {...props}
    >
      {orderData.isArchived ? "Unarchive" : "Archive"}
    </Button>
  );
};
