import React, { useMemo } from "react";
import {
  Box,
  HStack,
  RadioProps,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";

interface OptionProps extends RadioProps {}
const Option: React.FC<OptionProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "teal.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={4}
        py={2}
      >
        {props.children}
      </Box>
    </Box>
  );
};

type BooleanFilterValue = true | false | undefined;
interface BooleanFilterProps {
  name: string;
  onChange: (val: BooleanFilterValue) => void;
  selected: BooleanFilterValue;
}

export const IsArchivedFilterInput: React.FC<BooleanFilterProps> = ({
  name,
  onChange,
  selected,
}) => {
  const options: Map<BooleanFilterValue, string> = useMemo(
    () =>
      new Map<BooleanFilterValue, string>([
        [false, "Active"],
        [true, "Archived"],
        [undefined, "All"],
      ]),
    []
  );
  const reverseOptions: Map<string, BooleanFilterValue> = useMemo(
    () => new Map(Array.from(options, (x) => [x[1], x[0]])),
    [options]
  );
  const arrayOptions = useMemo(() => [...options], [options]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    defaultValue: options.get(selected),
    onChange: (val) => onChange(reverseOptions.get(val)),
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {arrayOptions.map(([_, value]) => {
        const radio = getRadioProps({ value });
        return (
          <Option key={value} {...radio}>
            {value}
          </Option>
        );
      })}
    </HStack>
  );
};

export const IsUnhandledFilterInput: React.FC<BooleanFilterProps> = ({
  name,
  onChange,
  selected,
}) => {
  const options: Map<BooleanFilterValue, string> = useMemo(
    () =>
      new Map<BooleanFilterValue, string>([
        [true, "Requires action"],
        [false, "Handled"],
        [undefined, "All"],
      ]),
    []
  );
  const reverseOptions: Map<string, BooleanFilterValue> = useMemo(
    () => new Map(Array.from(options, (x) => [x[1], x[0]])),
    [options]
  );
  const arrayOptions = useMemo(() => [...options], [options]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    defaultValue: options.get(selected),
    onChange: (val) => onChange(reverseOptions.get(val)),
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {arrayOptions.map(([_, value]) => {
        const radio = getRadioProps({ value });
        return (
          <Option key={value} {...radio}>
            {value}
          </Option>
        );
      })}
    </HStack>
  );
};
