import { Box, Button, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { Panel } from "./Panel";
import React from "react";
import {
  URL_AUTOMATION_CREATED_ORDERS,
  URL_AUTOMATION_LOG,
} from "../utils/urls";
import { Link } from "react-router-dom";
import { FalconResupplyAutomationConfigFragment } from "../generated/FalconResupplyAutomationConfigFragment";
import { RunButton } from "./Automation/RunButton";

export enum AutomationPageName {
  OVERVIEW = "OVERVIEW",
  CREATED_ORDERS = "CREATED_ORDERS",
  LOGS = "LOGS",
}

interface AutomationLayoutProps {
  automation: FalconResupplyAutomationConfigFragment;
  activePage: AutomationPageName;
}
export const AutomationLayout: React.FunctionComponent<AutomationLayoutProps> = ({
  automation,
  activePage,
  children,
}) => {
  const customer = automation.customer;
  return (
    <Box>
      <Heading mb={8}>{customer.name}</Heading>
      <SimpleGrid columns={3} mb={5} spacing={5}>
        <Panel>
          <Text style={{ fontWeight: "bold" }}>Address</Text>
          <Text>{customer.address}</Text>
          <Text>
            {customer.postalCode} {customer.city}
          </Text>
          <Text casing={"capitalize"}>{customer.country}</Text>
        </Panel>
        <Panel>
          <Text style={{ fontWeight: "bold" }}>Confirmation Phone</Text>
          <Text>{automation.confirmationPhoneNumber}</Text>
        </Panel>
        <Panel>
          <Text style={{ fontWeight: "bold" }}>Customer Code</Text>
          <Text>{customer.customerCode}</Text>
        </Panel>
      </SimpleGrid>
      <Panel p={2} mb={5}>
        <Flex justifyContent={"space-between"}>
          <Box>
            <Link to={URL_AUTOMATION_CREATED_ORDERS.builder(automation.id)}>
              <Button
                mx={2}
                isActive={activePage === AutomationPageName.CREATED_ORDERS}
              >
                Created Orders
              </Button>
            </Link>
            <Link to={URL_AUTOMATION_LOG.builder(automation.id)}>
              <Button mx={2} isActive={activePage === AutomationPageName.LOGS}>
                Log
              </Button>
            </Link>
          </Box>
          <RunButton automation={automation} />
        </Flex>
      </Panel>
      {children}
    </Box>
  );
};
