import React from "react";
import { useQuery } from "@apollo/client";
import { FalconFutureOrderFragment } from "../../generated/FalconFutureOrderFragment";
import { ContentTypeQuery } from "../../api/queries";
import { GetContentTypes } from "../../generated/GetContentTypes";
import { Text } from "@chakra-ui/react";
import { globalToPlainID } from "../../utils/id";
import { Panel } from "../Panel";
import { LogEntryList } from "../LogEntryList";

interface FutureOrderLogProps {
  order: FalconFutureOrderFragment;
}
export const FutureOrderLog: React.FC<FutureOrderLogProps> = ({ order }) => {
  const { data, loading, error } = useQuery<GetContentTypes>(ContentTypeQuery);
  const contentType = data?.contentTypes.falconFutureOrder;
  if (loading) {
    return (
      <Panel mb={5}>
        <Text>Loading...</Text>
      </Panel>
    );
  }
  if (!contentType || error) {
    return (
      <Panel mb={5}>
        <Text>Failed to load log</Text>
      </Panel>
    );
  }
  return (
    <LogEntryList
      contentType={contentType}
      objectId={parseInt(globalToPlainID(order.id))}
    />
  );
};
