import { Link, useLocation } from "react-router-dom";
import { Box, Heading, Text, Link as ChakraLink } from "@chakra-ui/react";
import React from "react";
import { FaCalendarAlt, FaCog, FaCogs, FaSignOutAlt } from "react-icons/fa";
import { IconType } from "react-icons/lib/esm/iconBase";
import {
  NAV_BG_ACTIVE,
  NAV_BG_INACTIVE,
  NAV_TEXT_ACTIVE,
  NAV_TEXT_INACTIVE,
} from "../theme";
import { URL_PENDING_ORDERS, UrlLink, URL_AUTOMATIONS } from "../utils/urls";
import { Session } from "../api/auth";
import { AppConfig } from "../config";

interface SidebarButtonProps {
  Icon: IconType;
  label: string;
  isActive?: boolean;
  onClick?: () => void;
}
const SidebarButton: React.FC<SidebarButtonProps> = ({
  Icon,
  label,
  isActive,
}) => {
  return (
    <Box
      py={2}
      backgroundColor={isActive ? NAV_BG_ACTIVE : NAV_BG_INACTIVE}
      color={isActive ? NAV_TEXT_ACTIVE : NAV_TEXT_INACTIVE}
      _hover={{
        backgroundColor: "gray.600",
        color: "gray.100",
        cursor: "pointer",
      }}
    >
      <Box display={"flex"} alignItems={"center"} px={2}>
        <Box mx={3}>
          <Icon />
        </Box>
        <Text fontSize={"md"}>{label}</Text>
      </Box>
    </Box>
  );
};

interface SidebarLinkProps {
  link: UrlLink;
  Icon: IconType;
}
const SidebarLink: React.FunctionComponent<SidebarLinkProps> = ({
  link,
  Icon,
}) => {
  const location = useLocation();

  const active =
    link.pattern === "/"
      ? location.pathname === link.pattern
      : location.pathname.startsWith(link.pattern);

  return (
    <Link to={link.builder()}>
      <SidebarButton Icon={Icon} isActive={active} label={link.title} />
    </Link>
  );
};

export const Sidebar: React.FunctionComponent = () => {
  return (
    <Box
      width={"255px"}
      backgroundColor={NAV_BG_INACTIVE}
      color={NAV_TEXT_INACTIVE}
      height={"100vh"}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <Heading textAlign={"center"} mt={8} mb={16}>
          Falcon
        </Heading>
        <SidebarLink Icon={FaCogs} link={URL_AUTOMATIONS} />
        {/*TODO: Enable*/}
        {/*<SidebarLink Icon={FaUsers} link={URL_CUSTOMERS} />*/}
        {/*<SidebarLink Icon={FaHome} link={URL_HOME} />*/}
        {/*<SidebarLink Icon={FaFileInvoiceDollar} link={URL_ORDERS} />*/}
        {/*<SidebarLink Icon={FaBoxes} link={URL_PRODUCTS} />*/}
        {/*<SidebarLink Icon={FaIndent} link={URL_TASK_LOG} />*/}
        {/*<SidebarLink Icon={FaUserCog} link={URL_USERS} />*/}
        <SidebarLink Icon={FaCalendarAlt} link={URL_PENDING_ORDERS} />
      </Box>
      <Box pb={6} marginTop={"auto"}>
        {AppConfig.ADMIN_URL ? (
          <ChakraLink
            href={AppConfig.ADMIN_URL}
            _hover={{ textDecor: "inherit" }}
          >
            <SidebarButton Icon={FaCog} label={"Admin Login"} />
          </ChakraLink>
        ) : null}
        <ChakraLink
          onClick={Session.logout}
          width={"100%"}
          _hover={{ textDecor: "inherit" }}
        >
          <SidebarButton Icon={FaSignOutAlt} label={"Log Out"} />
        </ChakraLink>
      </Box>
    </Box>
  );
};
