import React from "react";

import {
  GetFalconResupplyAutomationConfigQuery,
  ListFalconResupplyAutomationConfigQuery,
} from "../../api/queries";
import { FilterTable } from "../../components/FilterTable";
import { FilterTableDisplayWithoutPanel } from "../../components/FilterTableDisplay";
import { FalconCustomerOrdering } from "../../generated/globalTypes";
import {
  URL_AUTOMATION_CREATED_ORDERS,
  URL_AUTOMATION_LOG,
  URL_AUTOMATION_OVERVIEW,
} from "../../utils/urls";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Text } from "@chakra-ui/react";
import { Panel } from "../../components/Panel";
import { ListFalconResupplyAutomationConfig } from "../../generated/ListFalconResupplyAutomationConfig";
import { FalconResupplyAutomationConfigFragment } from "../../generated/FalconResupplyAutomationConfigFragment";
import { AutomationFutureOrders } from "./[id]/future";
import {
  GetFalconResupplyAutomationConfig,
  GetFalconResupplyAutomationConfigVariables,
} from "../../generated/GetFalconResupplyAutomationConfig";
import {
  AutomationLayout,
  AutomationPageName,
} from "../../components/AutomationLayout";
import { AutomationLog } from "./[id]/logs";

const AutomationList: React.FunctionComponent = () => {
  const history = useHistory();
  const onSelectRow = async (row: FalconResupplyAutomationConfigFragment) => {
    const href = URL_AUTOMATION_OVERVIEW.builder(row.id);
    await history.push(href);
  };

  return (
    <Panel p={0}>
      <FilterTable
        pageSize={10}
        query={ListFalconResupplyAutomationConfigQuery}
        variables={{ enabled: true } as any}
        mapQueryResult={(res: ListFalconResupplyAutomationConfig) =>
          res.listFalconResupplyAutomationConfig
        }
        tableComponent={FilterTableDisplayWithoutPanel}
        onSelectRow={onSelectRow}
        columns={[
          {
            Header: "Name",
            accessor: (row) => row.customer.name,
            orderingAsc: FalconCustomerOrdering.NAME_ASC,
            orderingDesc: FalconCustomerOrdering.NAME_DESC,
          },
          {
            Header: "Customer Code",
            accessor: (row) => row.customer.customerCode,
            orderingAsc: FalconCustomerOrdering.CUSTOMER_CODE_ASC,
            orderingDesc: FalconCustomerOrdering.CUSTOMER_CODE_DESC,
          },
          {
            Header: "Postal Code",
            accessor: (row) => row.customer.postalCode,
            orderingAsc: FalconCustomerOrdering.POSTAL_CODE_ASC,
            orderingDesc: FalconCustomerOrdering.POSTAL_CODE_DESC,
          },
          {
            Header: "City",
            accessor: (row) => row.customer.city,
            orderingAsc: FalconCustomerOrdering.CITY_ASC,
            orderingDesc: FalconCustomerOrdering.CITY_DESC,
          },
        ]}
      />
    </Panel>
  );
};

const AutomationIndex: React.FunctionComponent = () => {
  return <AutomationList />;
};

interface CustomerDetailProps {
  id: string;
}
const AutomationDetail: React.FC<CustomerDetailProps> = ({ id }) => {
  const { data, loading, error } = useQuery<
    GetFalconResupplyAutomationConfig,
    GetFalconResupplyAutomationConfigVariables
  >(GetFalconResupplyAutomationConfigQuery, {
    variables: { id: id },
  });

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    console.log(error);
    return <Text>Error: {error.message}</Text>;
  }

  if (!data || !data.getFalconResupplyAutomationConfig) {
    return <Text>Not found</Text>;
  }

  const automation: FalconResupplyAutomationConfigFragment =
    data.getFalconResupplyAutomationConfig;

  // TODO: Make activePage work
  return (
    <AutomationLayout
      automation={automation}
      activePage={AutomationPageName.OVERVIEW}
    >
      <Switch>
        <Route exact path={URL_AUTOMATION_OVERVIEW.pattern}>
          <Redirect to={URL_AUTOMATION_CREATED_ORDERS.builder(automation.id)} />
        </Route>
        <Route path={URL_AUTOMATION_CREATED_ORDERS.pattern}>
          <AutomationFutureOrders automation={automation} />
        </Route>
        <Route path={URL_AUTOMATION_LOG.pattern}>
          <AutomationLog automation={automation} />
        </Route>
      </Switch>
    </AutomationLayout>
  );
};

const AutomationRoutes = () => {
  const { id } = useParams<{ id: string | undefined }>();
  return id ? <AutomationDetail id={id} /> : null;
};

export const AutomationRouter: React.FunctionComponent = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <AutomationIndex />
      </Route>
      <Route path={URL_AUTOMATION_OVERVIEW.pattern}>
        <AutomationRoutes />
      </Route>
    </Switch>
  );
};
