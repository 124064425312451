import React, { PropsWithChildren, useMemo } from "react";
import { Accessor, Column, SortingRule } from "react-table";

import { ApolloError, useQuery } from "@apollo/client";
import { DocumentNode } from "graphql";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { RelayConnection, flattenEdges } from "../utils/edges";
import { FilterTableDisplayProps } from "./FilterTableDisplay";

type DataTableColumnDefinitions<TNode extends object, TOrdering> = {
  Header: string;
  accessor: Accessor<TNode>;
} & (
  | {
      orderingDisabled?: false | undefined;
      orderingDesc: TOrdering;
      orderingAsc: TOrdering;
    }
  | {
      orderingDisabled: true;
    }
);

interface PaginationVariables {
  count?: number | null;
  cursor?: string | null;
  query?: string | null;
  ordering?: any | null;
}

interface DataTableQueryHookProps<
  TData,
  TVariables extends PaginationVariables,
  TNode extends object,
  TOrdering
> {
  pageSize: number;
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  variables: TVariables;
  columns: DataTableColumnDefinitions<TNode, TOrdering>[];
  mapResult: (queryResult: TData) => RelayConnection<TNode> | null;
}
interface DataTableQuery<TNode extends object> {
  data: TNode[];
  fetchMore: () => void;
  loading: boolean;
  error?: ApolloError;
  hasMore: boolean;
  handleSortBy: (sortBy: SortingRule<object>[]) => void;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  columns: Column<TNode>[];
}
type DataTableQueryHook = <
  TData,
  TVariables extends PaginationVariables,
  TNode extends object,
  TOrdering
>(
  props: DataTableQueryHookProps<TData, TVariables, TNode, TOrdering>
) => DataTableQuery<TNode>;

export const useDataTableQuery: DataTableQueryHook = (props) => {
  const { columns, handleSortBy, ordering } = useDataTableColumns({
    columns: props.columns,
  });

  // TODO: Add debounce to search query?
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const baseVariables = useMemo(
    () => ({
      ...props.variables,
      count: props.pageSize,
      query: searchQuery,
      ordering: ordering,
    }),
    [props.variables, props.pageSize, searchQuery, ordering]
  );
  const { data, loading, error, fetchMore } = useQuery(props.query, {
    variables: baseVariables,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });

  const result = useMemo(() => (data ? props.mapResult(data) : undefined), [
    data,
    props,
  ]);
  const flattenedData = useMemo(() => flattenEdges(result), [result]);
  const paginationState = result?.pageInfo;

  return {
    data: flattenedData,
    fetchMore: () =>
      fetchMore({
        variables: {
          ...baseVariables,
          cursor: paginationState?.endCursor,
        },
      }),
    loading,
    error,
    hasMore: paginationState ? paginationState.hasNextPage : false,
    handleSortBy: handleSortBy,
    columns: columns,
    searchQuery: searchQuery,
    setSearchQuery: setSearchQuery,
  };
};

interface DataTableColumnsProps<TNode extends object, TOrdering> {
  columns: DataTableColumnDefinitions<TNode, TOrdering>[];
}
interface DataTableColumns<TNode extends object> {
  columns: Column<TNode>[];
  handleSortBy: (sortBy: SortingRule<object>[]) => void;
  ordering: any;
}

function useDataTableColumns<TNode extends object, TOrdering>(
  props: DataTableColumnsProps<TNode, TOrdering>
): DataTableColumns<TNode> {
  const [ordering, setOrdering] = React.useState<any | undefined>(undefined);

  const { columns, handleSortBy } = useMemo(() => {
    const result: Column<TNode>[] = [];
    const orderingMap: Map<string, (desc: boolean) => any> = new Map();

    let index = 0;
    for (const column of props.columns) {
      const columnId = `col-${index}`;
      result.push({
        Header: column.Header,
        id: columnId,
        accessor: column.accessor,
        disableSortBy: column.orderingDisabled,
      } as any); // TODO: Fix type. This is supported but type hints are weird somehow
      if (!column.orderingDisabled) {
        orderingMap.set(columnId, (desc) =>
          desc ? column.orderingDesc : column.orderingAsc
        );
      }
      index++;
    }

    const handleSortBy = (sortBy: SortingRule<object>[]) => {
      const firstSort = sortBy.length > 0 ? sortBy[0] : undefined;
      let ordering = undefined;
      if (firstSort) {
        const orderingGetter = orderingMap.get(firstSort.id);
        ordering = orderingGetter
          ? orderingGetter(!!firstSort.desc)
          : undefined;
      }
      setOrdering(ordering);
    };

    return { columns: result, handleSortBy: handleSortBy };
  }, [props.columns]);

  return {
    columns,
    handleSortBy,
    ordering,
  };
}

interface FilterTableProps<
  TResult,
  TVariables extends PaginationVariables,
  TNode extends object,
  TOrdering
> {
  pageSize: number;
  query: DocumentNode | TypedDocumentNode<TResult, TVariables>;
  mapQueryResult: (queryResult: TResult) => RelayConnection<TNode> | null;
  columns: DataTableColumnDefinitions<TNode, TOrdering>[];
  variables: TVariables;
  tableComponent: React.FC<FilterTableDisplayProps<TNode>>;
  onSelectRow?: (row: TNode) => void;
}

export function FilterTable<
  TResult,
  TVariables extends PaginationVariables,
  TNode extends object,
  TOrdering
>(
  props: PropsWithChildren<
    FilterTableProps<TResult, TVariables, TNode, TOrdering>
  >
) {
  const {
    data,
    fetchMore,
    hasMore,
    columns,
    handleSortBy,
    searchQuery,
    setSearchQuery,
  } = useDataTableQuery({
    pageSize: props.pageSize,
    query: props.query,
    mapResult: props.mapQueryResult,
    variables: props.variables,
    columns: props.columns,
  });

  return (
    <props.tableComponent
      columns={columns}
      data={data}
      setSortBy={handleSortBy}
      fetchMore={fetchMore}
      hasMore={hasMore}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      onSelectRow={props.onSelectRow}
    >
      {props.children}
    </props.tableComponent>
  );
}
