export interface EdgefulData<T> {
  edges?:
    | (
        | {
            node?: T | null;
          }
        | undefined
        | null
      )[]
    | null;
}

export interface RelayConnection<T> extends EdgefulData<T> {
  pageInfo: {
    endCursor: string | null;
    hasNextPage: boolean;
  };
}

export const flattenEdges = <T>(
  data?: RelayConnection<T> | EdgefulData<T> | null
): T[] => {
  if (!data) return [];
  if (!data.edges) return [];
  return data.edges
    .filter((x) => !!x)
    .map((x) => x!.node)
    .filter((x) => !!x) as T[];
};
