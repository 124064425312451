import { Column, SortingRule, useSortBy, useTable } from "react-table";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import React, { PropsWithChildren, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Panel } from "./Panel";

export interface FilterTableDisplayProps<TNode extends object> {
  columns: Column<TNode>[];
  data: TNode[];
  setSortBy: (sortBy: SortingRule<object>[]) => void;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  hasMore: boolean;
  fetchMore: () => void;
  onSelectRow?: (row: TNode) => void;
}
export function FilterTableDisplay<TNode extends object>({
  columns,
  data,
  setSortBy,
  fetchMore,
  hasMore,
  searchQuery,
  setSearchQuery,
  onSelectRow,
  children,
}: PropsWithChildren<FilterTableDisplayProps<TNode>>) {
  return (
    <Panel p={0}>
      <FilterTableDisplayWithoutPanel
        columns={columns}
        data={data}
        setSortBy={setSortBy}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        hasMore={hasMore}
        fetchMore={fetchMore}
        onSelectRow={onSelectRow}
      >
        {children}
      </FilterTableDisplayWithoutPanel>
    </Panel>
  );
}

export interface FilterTableDisplayWithoutPanelProps<TNode extends object> {
  columns: Column<TNode>[];
  data: TNode[];
  setSortBy: (sortBy: SortingRule<object>[]) => void;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  hasMore: boolean;
  fetchMore: () => void;
  onSelectRow?: (row: TNode) => void;
}
export function FilterTableDisplayWithoutPanel<TNode extends object>({
  columns,
  data,
  setSortBy,
  fetchMore,
  hasMore,
  searchQuery,
  setSearchQuery,
  onSelectRow,
  children,
}: PropsWithChildren<FilterTableDisplayProps<TNode>>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns: columns,
      data: data,
      manualSortBy: true,
      disableMultiSort: true,
      autoResetSortBy: false,
    },
    useSortBy
  );

  useEffect(() => setSortBy(sortBy), [sortBy, setSortBy]);

  const hover = onSelectRow
    ? {
        textDecor: "underline",
        backgroundColor: "gray.200",
        cursor: "pointer",
      }
    : undefined;

  const rowClick = async (
    e: React.MouseEvent<HTMLTableRowElement>,
    row: TNode
  ) => {
    if (onSelectRow) {
      e.preventDefault();
      await onSelectRow(row);
    }
  };

  return (
    <>
      <Box p={2}>
        <Input
          placeholder="Search"
          value={searchQuery}
          onChange={(evt) => setSearchQuery(evt.target.value)}
        />
      </Box>
      {children}
      <Table variant={"simple"} {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => {
            return (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <Flex direction={"row"} align={"center"}>
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDownIcon ml={1} />
                          ) : (
                            <ArrowUpIcon ml={1} />
                          )
                        ) : (
                          <Box ml={1} width={"1em"} height={"1em"} />
                        )}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                _hover={hover}
                onClick={(e) => rowClick(e, row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Box p={2}>
        <Button onClick={fetchMore} disabled={!hasMore}>
          Load more
        </Button>
      </Box>
    </>
  );
}
