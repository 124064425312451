import { gql } from "@apollo/client/core";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button } from "@chakra-ui/react";
import { ButtonProps } from "@chakra-ui/button/dist/types/button";
import { ListLogEntryQuery } from "../../api/queries";
import {
  AutomationRun,
  AutomationRunVariables,
} from "../../generated/AutomationRun";
import { FalconResupplyAutomationConfigFragment } from "../../generated/FalconResupplyAutomationConfigFragment";
import { FaPlay } from "react-icons/all";

const RUN_AUTOMATION = gql`
  mutation AutomationRun($automationId: GlobalIDInput!) {
    automationRun(input: { automationId: $automationId }) {
      success
    }
  }
`;

interface RunButtonProps extends ButtonProps {
  automation: FalconResupplyAutomationConfigFragment;
  callback?: () => void;
}
export const RunButton: React.FC<RunButtonProps> = ({
  automation,
  callback,
  ...props
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [mutateRun] = useMutation<AutomationRun, AutomationRunVariables>(
    RUN_AUTOMATION,
    {
      errorPolicy: "all",
      refetchQueries: [ListLogEntryQuery],
    }
  );

  const run = () => {
    setIsDisabled(true);
    mutateRun({
      variables: {
        automationId: automation.id,
      },
    }).finally(() => {
      setTimeout(() => setIsDisabled(false), 5000);
      if (callback) callback();
    });
  };

  return (
    <Button
      leftIcon={<FaPlay />}
      colorScheme={"blue"}
      onClick={run}
      disabled={isDisabled}
      {...props}
    >
      {"Run resupply check"}
    </Button>
  );
};
