import React from "react";
import { FalconResupplyAutomationConfigFragment } from "../../../generated/FalconResupplyAutomationConfigFragment";
import { useQuery } from "@apollo/client";
import { GetContentTypes } from "../../../generated/GetContentTypes";
import { ContentTypeQuery } from "../../../api/queries";
import { Panel } from "../../../components/Panel";
import { Text } from "@chakra-ui/react";
import { LogEntryList } from "../../../components/LogEntryList";
import { globalToPlainID } from "../../../utils/id";

interface AutomationLogProps {
  automation: FalconResupplyAutomationConfigFragment;
}
export const AutomationLog: React.FunctionComponent<AutomationLogProps> = ({
  automation,
}) => {
  const { data, loading, error } = useQuery<GetContentTypes>(ContentTypeQuery);
  const contentType = data?.contentTypes.falconResupplyAutomationConfig;
  if (loading) {
    return (
      <Panel mb={5}>
        <Text>Loading...</Text>
      </Panel>
    );
  }
  if (!contentType || error) {
    return (
      <Panel mb={5}>
        <Text>Failed to load log</Text>
      </Panel>
    );
  }
  return (
    <LogEntryList
      contentType={contentType}
      objectId={parseInt(globalToPlainID(automation.id))}
    />
  );
};
