import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import React from "react";
import { NAV_BG_ACTIVE, NAV_TEXT_ACTIVE } from "../theme";
import { Link } from "react-router-dom";

interface BreadcrumbsProps {}
export const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = () => {
  const crumbs = [];
  const links = [
    { title: "Customers", href: "/customer" },
    // { title: "Test Customer", href: "/customer" },
    // { title: "Orders", href: "/customer" },
  ];
  for (const [index, link] of Object.entries(links)) {
    crumbs.push(
      <BreadcrumbItem key={index}>
        <BreadcrumbLink as={Link} to={link.href}>
          <Box _hover={{ textDecor: "underline" }}>{link.title}</Box>
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  }

  crumbs.pop(); // TODO: Remove once figured out

  return (
    <Breadcrumb backgroundColor={NAV_BG_ACTIVE} color={NAV_TEXT_ACTIVE} p={4}>
      {crumbs}
    </Breadcrumb>
  );
};
