import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import {
  URL_AUTOMATIONS,
  URL_CUSTOMERS,
  URL_PENDING_ORDERS,
} from "../utils/urls";
import LocationIndex from "./location";
// TODO: Enable
// import OrderIndex from "./pages/order";
// import ProductIndex from "./pages/product";
// import TaskLogIndex from "./pages/task-log";
// import UserIndex from "./pages/user";
import ScheduledOrderIndex from "./upcoming-order";
import { Layout } from "../components/Layout";
import { Heading } from "@chakra-ui/react";
import React from "react";
import { LoginProvider } from "./login";
import { AutomationRouter } from "./automation";

const LoggedInRoutes: React.FC = () => {
  return (
    <Layout>
      <Switch>
        <Route path={URL_AUTOMATIONS.pattern}>
          <Route exact path={URL_AUTOMATIONS.pattern}>
            <Heading mb={8}>{URL_AUTOMATIONS.title}</Heading>
          </Route>
          <AutomationRouter />
        </Route>
        <Route path={URL_PENDING_ORDERS.pattern}>
          <Route exact path={URL_PENDING_ORDERS.pattern}>
            <Heading mb={8}>{URL_PENDING_ORDERS.title}</Heading>
          </Route>
          <ScheduledOrderIndex />
        </Route>
        <Route path={URL_CUSTOMERS.pattern}>
          <Route exact path={URL_CUSTOMERS.pattern}>
            <Heading mb={8}>{URL_CUSTOMERS.title}</Heading>
          </Route>
          <LocationIndex />
        </Route>
        <Route path={"/"}>
          <Redirect to={URL_PENDING_ORDERS.builder()} />
        </Route>
        {/*TODO: Enable*/}
        {/*<Route path={URL_ORDERS.pattern}>*/}
        {/*  <OrderIndex />*/}
        {/*</Route>*/}
        {/*<Route path={URL_PRODUCTS.pattern}>*/}
        {/*  <ProductIndex />*/}
        {/*</Route>*/}
        {/*<Route path={URL_TASK_LOG.pattern}>*/}
        {/*  <TaskLogIndex />*/}
        {/*</Route>*/}
        {/*<Route path={URL_USERS.pattern}>*/}
        {/*  <UserIndex />*/}
        {/*</Route>*/}
        {/*<Route path={URL_HOME.pattern}>*/}
        {/*  <Index />*/}
        {/*</Route>*/}
      </Switch>
    </Layout>
  );
};

export const FalconRouter = () => {
  return (
    <BrowserRouter>
      <LoginProvider>
        <LoggedInRoutes />
      </LoginProvider>
    </BrowserRouter>
  );
};
