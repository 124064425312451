export const formatDateMonthYear = (dateString: string) => {
  const date = new Date(Date.parse(dateString));
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};

export const formatDateMonthYearHourMinute = (dateString: string) => {
  const date = new Date(Date.parse(dateString));
  const hour = ("00" + date.getHours()).slice(-2);
  const minute = ("00" + date.getMinutes()).slice(-2);
  return `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()}\u00a0${hour}:${minute}`;
};
