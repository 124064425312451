import React, { useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  Text,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { FilterTable } from "./FilterTable";
import { ListFalconFutureOrdersQuery } from "../api/queries";
import {
  ListFalconFutureOrders,
  ListFalconFutureOrdersVariables,
} from "../generated/ListFalconFutureOrders";
import { FilterTableDisplay } from "./FilterTableDisplay";
import {
  FalconFutureOrderCustomerConfirmationStatus,
  FalconFutureOrderFoodduckReviewStatus,
  FalconFutureOrderOrdering,
  FalconFutureOrderStatus,
} from "../generated/globalTypes";
import {
  formatDateMonthYear,
  formatDateMonthYearHourMinute,
} from "../utils/datetime";
import { FalconFutureOrderFragment } from "../generated/FalconFutureOrderFragment";
import { FalconFutureOrderDetails } from "./FutureOrderDetails";
import {
  IsArchivedFilterInput,
  IsUnhandledFilterInput,
} from "./FutureOrderList/FilterInputs";

type DisplayStatus =
  | "Archived"
  | "Expired"
  | "Review"
  | "Customer Review"
  | "Order Creation"
  | "Complete"
  | "Rejected"
  | "Unknown";

const getStatusColor = (status: DisplayStatus) => {
  if (status === "Expired" || status === "Archived") {
    return "gray";
  } else if (status === "Review") {
    return "orange";
  } else if (status === "Customer Review") {
    return "yellow";
  } else if (status === "Order Creation") {
    return "blue";
  } else if (status === "Complete") {
    return "green";
  } else if (status === "Rejected") {
    return "red";
  }
  return undefined;
};

const formatStatusText = (order: FalconFutureOrderFragment): DisplayStatus => {
  if (order.isArchived) {
    return "Archived";
  } else if (
    order.foodduckReviewStatus ===
      FalconFutureOrderFoodduckReviewStatus.EXPIRED ||
    order.customerConfirmationStatus ===
      FalconFutureOrderCustomerConfirmationStatus.EXPIRED
  )
    return "Expired";
  else if (order.status === FalconFutureOrderStatus.FOODDUCK_CONFIRMATION) {
    return "Review";
  } else if (order.status === FalconFutureOrderStatus.CUSTOMER_CONFIRMATION) {
    return "Customer Review";
  } else if (order.status === FalconFutureOrderStatus.ORDER_HANDLING) {
    return "Order Creation";
  } else if (order.status === FalconFutureOrderStatus.FINISHED_SUCCESS) {
    return "Complete";
  } else if (order.status === FalconFutureOrderStatus.FINISHED_FAILURE) {
    return "Rejected";
  }
  return "Unknown";
};

interface FalconFutureOrderListProps {
  queryVariables: ListFalconFutureOrdersVariables;
}
export const FutureOrderList: React.FC<FalconFutureOrderListProps> = ({
  queryVariables,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOrder, setSelectedOrder] = useState<
    FalconFutureOrderFragment | undefined
  >(undefined);
  const [isArchived, setIsArchived] = useState<boolean | undefined>(false);
  const [isUnhandled, setIsUnhandled] = useState<boolean | undefined>(true);

  const RowStatus: React.FC<{ row: FalconFutureOrderFragment }> = ({ row }) => {
    const status = formatStatusText(row);
    return <Button colorScheme={getStatusColor(status)}>{status}</Button>;
  };

  const onSelectRow = async (row: FalconFutureOrderFragment) => {
    setSelectedOrder(row);
    onOpen();
  };

  return (
    <>
      <FilterTable
        pageSize={10}
        query={ListFalconFutureOrdersQuery}
        variables={{
          ...queryVariables,
          isArchived: isArchived,
          isUnhandled: isUnhandled,
        }}
        mapQueryResult={(res: ListFalconFutureOrders) =>
          res.listFalconFutureOrder
        }
        tableComponent={FilterTableDisplay}
        onSelectRow={onSelectRow}
        columns={[
          {
            Header: "Customer Name",
            accessor: (row) => row.billingCustomer.name,
            orderingAsc: FalconFutureOrderOrdering.BILLING_CUSTOMER__NAME_ASC,
            orderingDesc: FalconFutureOrderOrdering.BILLING_CUSTOMER__NAME_DESC,
          },
          {
            Header: "Customer Code",
            accessor: (row) => row.billingCustomer.customerCode,
            orderingAsc:
              FalconFutureOrderOrdering.BILLING_CUSTOMER__CUSTOMER_CODE_ASC,
            orderingDesc:
              FalconFutureOrderOrdering.BILLING_CUSTOMER__CUSTOMER_CODE_DESC,
          },
          {
            Header: "Location Code",
            accessor: (row) => row.deliveryCustomer.customerCode,
            orderingAsc:
              FalconFutureOrderOrdering.DELIVERY_CUSTOMER__CUSTOMER_CODE_ASC,
            orderingDesc:
              FalconFutureOrderOrdering.DELIVERY_CUSTOMER__CUSTOMER_CODE_DESC,
          },
          {
            Header: "Postal Code",
            accessor: (row) => row.deliveryCustomer.postalCode,
            orderingAsc:
              FalconFutureOrderOrdering.DELIVERY_CUSTOMER__POSTAL_CODE_ASC,
            orderingDesc:
              FalconFutureOrderOrdering.DELIVERY_CUSTOMER__POSTAL_CODE_DESC,
          },
          {
            Header: "Delivery Date",
            accessor: (row) => formatDateMonthYear(row.shipDate),
            orderingAsc: FalconFutureOrderOrdering.SHIP_DATE_ASC,
            orderingDesc: FalconFutureOrderOrdering.SHIP_DATE_DESC,
          },
          {
            Header: "Expires On",
            accessor: (row) => formatDateMonthYearHourMinute(row.expiryDate),
            orderingAsc: FalconFutureOrderOrdering.EXPIRY_DATE_ASC,
            orderingDesc: FalconFutureOrderOrdering.EXPIRY_DATE_DESC,
          },
          {
            Header: "Status",
            accessor: (row) => <RowStatus row={row} />,
            orderingAsc: FalconFutureOrderOrdering.STATUS_ASC,
            orderingDesc: FalconFutureOrderOrdering.STATUS_DESC,
          },
        ]}
      >
        <HStack p={2} mb={4} spacing={"28px"}>
          <VStack ml={4}>
            <Text fontWeight={"bold"}>Archival status</Text>
            <IsArchivedFilterInput
              name={"isArchived"}
              onChange={setIsArchived}
              selected={isArchived}
            />
          </VStack>
          <VStack ml={4}>
            <Text fontWeight={"bold"}>Handling status</Text>
            <IsUnhandledFilterInput
              name={"isUnhandled"}
              onChange={setIsUnhandled}
              selected={isUnhandled}
            />
          </VStack>
        </HStack>
        <Divider />
      </FilterTable>
      <Drawer isOpen={isOpen} onClose={onClose} placement={"right"} size={"xl"}>
        <DrawerOverlay />
        <DrawerContent backgroundColor={"gray.100"}>
          <DrawerCloseButton />
          <DrawerHeader>Order Contents</DrawerHeader>

          <DrawerBody>
            {!!selectedOrder && (
              <FalconFutureOrderDetails order={selectedOrder} />
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button variant={"outline"} mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
