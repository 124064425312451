import { FalconOrderFragment } from "../../../generated/FalconOrderFragment";
import React from "react";
import { flattenEdges } from "../../../utils/edges";
import { Panel } from "../../../components/Panel";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { FalconProductProductType } from "../../../generated/globalTypes";
import { formatDateMonthYear } from "../../../utils/datetime";
import { FalconCustomerFragment } from "../../../generated/FalconCustomerFragment";
import { useQuery } from "@apollo/client";
import {
  ListFalconOrders,
  ListFalconOrdersVariables,
} from "../../../generated/ListFalconOrders";
import { ListFalconOrdersQuery } from "../../../api/queries";
interface FalconOrderDetailsProps {
  order: FalconOrderFragment;
}
const FalconOrderDetails: React.FunctionComponent<FalconOrderDetailsProps> = ({
  order,
}) => {
  const lines = flattenEdges(order.lines);
  return (
    <Panel p={0}>
      <Table>
        <Thead>
          <Tr>
            <Th>Product Code</Th>
            <Th>Description</Th>
            <Th>Price</Th>
            <Th>Quantity</Th>
          </Tr>
        </Thead>
        <Tbody>
          {lines.map((line) => (
            <Tr key={line.id}>
              <Td>{line.product?.productCode}</Td>
              <Td>{line.description}</Td>
              <Td>{line.priceTotal}</Td>
              <Td>{line.quantity}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Panel>
  );
};

interface FalconOrderProps {
  order: FalconOrderFragment;
}
const FalconOrder: React.FunctionComponent<FalconOrderProps> = (props) => {
  const lines = flattenEdges(props.order.lines);
  const butterAmount = lines
    .map((val) =>
      val.product && val.product.productType === FalconProductProductType.BUTTER
        ? val.product.contentWeightGrams * val.quantity
        : 0
    )
    .reduce((prev, current) => prev + current);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tr
        onClick={onOpen}
        _hover={{
          textDecor: "underline",
          backgroundColor: "gray.200",
          cursor: "pointer",
        }}
      >
        <Td>{props.order.dlprimeOrderNumber}</Td>
        <Td>{formatDateMonthYear(props.order.orderDate)}</Td>
        <Td>{formatDateMonthYear(props.order.shipDate)}</Td>
        <Td>{props.order.buyersReference}</Td>
        <Td>{props.order.priceTotal}</Td>
        <Td>{butterAmount}</Td>
      </Tr>
      <Drawer isOpen={isOpen} onClose={onClose} placement={"right"} size={"xl"}>
        <DrawerOverlay />
        <DrawerContent backgroundColor={"gray.100"}>
          <DrawerCloseButton />
          <DrawerHeader>Order Contents</DrawerHeader>

          <DrawerBody>
            <FalconOrderDetails order={props.order} />
          </DrawerBody>

          <DrawerFooter>
            <Button variant={"outline"} mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

interface CustomerDeliveryOrdersProps {
  customer: FalconCustomerFragment;
}
export const CustomerDeliveryOrders: React.FunctionComponent<CustomerDeliveryOrdersProps> = (
  props
) => {
  const { data, loading, error } = useQuery<
    ListFalconOrders,
    ListFalconOrdersVariables
  >(ListFalconOrdersQuery, {
    variables: { deliveryCustomerId: props.customer.id },
  });

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    console.log(error);
    return <Text>Error: {error.message}</Text>;
  }

  return (
    <Panel p={0}>
      <Table variant={"simple"}>
        <Thead>
          <Tr>
            <Th>Order Number</Th>
            <Th>Order Date</Th>
            <Th>Delivery Date</Th>
            <Th>Buyers Reference</Th>
            <Th>Total Price</Th>
            <Th>Butter Amount (g)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {flattenEdges(data?.listFalconOrder).map((order) => (
            <FalconOrder key={order.id} order={order} />
          ))}
        </Tbody>
      </Table>
    </Panel>
  );
};
