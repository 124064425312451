import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { Session } from "./auth";
import { AppConfig } from "../config";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) console.warn("[Network error]", networkError);

  if (graphQLErrors?.length) {
    graphQLErrors.forEach((err) => console.warn("[GraphQL error]", err));
    const tokenInvalid = graphQLErrors.some(
      (x) => x.message === "Invalid token."
    );
    if (tokenInvalid) {
      Session.logout();
    }
  }
});
const retry = new RetryLink({
  delay: {
    initial: 300,
    max: 5000,
    jitter: false,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const authLink = setContext(() => {
  const token = Session.token;
  if (token) {
    return {
      headers: { authorization: `Token ${token}` },
    };
  }
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    // errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "cache-first",
    // errorPolicy: "all",
  },
};

const link = new HttpLink({ uri: AppConfig.API_URL || undefined });

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          listNodeDailyUsageReport: relayStylePagination(),
          listFalconCustomer: relayStylePagination(),
          listDeliveryFalconCustomer: relayStylePagination(),
          listFalconFutureOrder: relayStylePagination([
            "deliveryCustomer",
            "isUnhandled",
            "isArchived",
          ]),
          listLocation: relayStylePagination(),
          listFalconProduct: relayStylePagination(),
          listFalconOrder: relayStylePagination(),
          listUser: relayStylePagination(),
          listDLPrimeIntegrationLog: relayStylePagination(),
          listMyFoodduckIntegrationLog: relayStylePagination(),
          listFalconOrderImportLog: relayStylePagination(),
          listLogEntry: relayStylePagination([
            "contentType",
            "objectId",
            "linkedObjContentType",
            "linkedObjId",
          ]),
          listTwilioMessage: relayStylePagination(["topic"]),
          listFalconResupplyAutomationConfig: relayStylePagination(["enabled"]),
        },
      },
    },
  }),
  link: ApolloLink.from([errorLink, retry, authLink, link]),
  defaultOptions: defaultOptions,
});

export default client;
