import { Base64 } from "js-base64";

// eslint-disable-next-line no-template-curly-in-string
const CONFIG_TEMPLATE = "${APPCONFIG_B64}";

interface _AppConfig {
  readonly API_URL: string | null;
  readonly ADMIN_URL: string | null;
}

const defaultConfig: _AppConfig = {
  API_URL: process.env.REACT_APP_API_URL || null,
  ADMIN_URL: process.env.REACT_APP_ADMIN_URL || null,
};

const loadAppConfig = (): _AppConfig => {
  const encodedConfig = (window as any)._configPayload as string;
  let appConfig = {};
  if (encodedConfig && encodedConfig !== CONFIG_TEMPLATE) {
    try {
      const json = Base64.decode(encodedConfig);
      appConfig = Object.fromEntries(
        Object.entries(JSON.parse(json)).filter(([_, v]) => v !== "")
      );
    } catch (e) {
      console.error(e);
    }
  }
  return {
    ...defaultConfig,
    ...appConfig,
  };
};

export const AppConfig: _AppConfig = loadAppConfig();
