import { makeObservable, observable, action } from "mobx";

const TOKEN_KEY = "authToken";

class AuthSession {
  token: string | null = null;

  constructor() {
    makeObservable(this, {
      token: observable,
      setToken: action,
      logout: action.bound,
    });
    this.token = localStorage.getItem(TOKEN_KEY);
  }

  setToken(token: string | null) {
    this.token = token;
    if (!token) {
      localStorage.removeItem(TOKEN_KEY);
    } else {
      localStorage.setItem(TOKEN_KEY, token);
    }
  }

  logout() {
    this.setToken(null);
  }
}

export const Session = new AuthSession();
