import { extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";

export const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
  components: {
    Steps,
  },
});

export const NAV_TEXT_ACTIVE = "gray.50";
export const NAV_TEXT_INACTIVE = "gray.400";
export const NAV_BG_ACTIVE = "gray.500";
export const NAV_BG_INACTIVE = "gray.700";
