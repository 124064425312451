type ParamBuilder<T> = (params: T) => string;
type Builder = () => string;

export interface UrlLink<T extends ParamBuilder<any> | Builder = Builder> {
  pattern: string;
  builder: T;
  title: string;
}

export const URL_AUTOMATIONS: UrlLink = {
  pattern: "/automation",
  builder: () => "/automation",
  title: "Automations",
};

export const URL_AUTOMATION_OVERVIEW: UrlLink<ParamBuilder<string>> = {
  pattern: "/automation/:id",
  builder: (id: string) => `/automation/${id}`,
  title: "Selected Automation", // TODO: How do we get the object name?
};

export const URL_AUTOMATION_LOG: UrlLink<ParamBuilder<string>> = {
  pattern: "/automation/:id/logs",
  builder: (id: string) => `/automation/${id}/logs`,
  title: "Selected Automation", // TODO: How do we get the object name?
};

export const URL_AUTOMATION_CREATED_ORDERS: UrlLink<ParamBuilder<string>> = {
  pattern: "/automation/:id/created-orders",
  builder: (id: string) => `/automation/${id}/created-orders`,
  title: "Selected Automation", // TODO: How do we get the object name?
};

export const URL_CUSTOMERS: UrlLink = {
  pattern: "/customer",
  builder: () => "/customer",
  title: "Customers",
};

export const URL_LOCATION_OVERVIEW: UrlLink<ParamBuilder<string>> = {
  pattern: "/customer/:id",
  builder: (id: string) => `/customer/${id}`,
  title: "Selected Customer", // TODO: How do we get the object name?
};

export const URL_LOCATION_ORDERS: UrlLink<ParamBuilder<string>> = {
  pattern: "/customer/:id/orders",
  builder: (id: string) => `/customer/${id}/orders`,
  title: "Selected Customer", // TODO: How do we get the object name?
};

export const URL_LOCATION_SETTINGS: UrlLink<ParamBuilder<string>> = {
  pattern: "/customer/:id/settings",
  builder: (id: string) => `/customer/${id}/settings`,
  title: "Selected Customer", // TODO: How do we get the object name?
};

export const URL_LOCATION_USAGE: UrlLink<ParamBuilder<string>> = {
  pattern: "/customer/:id/usage",
  builder: (id: string) => `/customer/${id}/usage`,
  title: "Selected Customer", // TODO: How do we get the object name?
};

export const URL_LOCATION_FUTURE: UrlLink<ParamBuilder<string>> = {
  pattern: "/customer/:id/pending",
  builder: (id: string) => `/customer/${id}/pending`,
  title: "Selected Customer", // TODO: How do we get the object name?
};
export const URL_PENDING_ORDERS: UrlLink = {
  pattern: "/pending-order",
  builder: () => "/pending-order",
  title: "Pending Orders",
};

// TODO: Enable
// export const URL_ORDERS: UrlLink = {
//   pattern: "/order",
//   builder: () => "/order",
//   title: "Orders",
// };
// export const URL_PRODUCTS: UrlLink = {
//   pattern: "/product",
//   builder: () => "/product",
//   title: "Products",
// };
// export const URL_TASK_LOG: UrlLink = {
//   pattern: "/task-log",
//   builder: () => "/task-log",
//   title: "Task Logs",
// };
// export const URL_USERS: UrlLink = {
//   pattern: "/user",
//   builder: () => "/user",
//   title: "Users",
// };
// export const URL_HOME: UrlLink = {
//   pattern: "/",
//   builder: () => "/",
//   title: "Home",
// };
