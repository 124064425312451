import React from "react";
import { ApolloProvider } from "@apollo/client";
import client from "./api/apollo";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import { FalconRouter } from "./pages/Router";
import "@fontsource/inter/400.css";
import "@fontsource/inter/700.css";

function App() {
  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <FalconRouter />
      </ChakraProvider>
    </ApolloProvider>
  );
}

export default App;
