import React from "react";
import { FalconCustomerFragment } from "../../../generated/FalconCustomerFragment";
import { FutureOrderList } from "../../../components/FutureOrderList";

interface LocationFutureOrdersProps {
  customer: FalconCustomerFragment;
}
export const LocationFutureOrders: React.FunctionComponent<LocationFutureOrdersProps> = ({
  customer,
}) => {
  return <FutureOrderList queryVariables={{ deliveryCustomer: customer.id }} />;
};
