import { chakra } from "@chakra-ui/system";
import { forwardRef, HTMLChakraProps } from "@chakra-ui/react";
import React from "react";

export interface PanelProps extends HTMLChakraProps<"div"> {}

export const Panel: React.FunctionComponent<PanelProps> = forwardRef<
  PanelProps,
  "div"
>((props, ref) => {
  return (
    <chakra.div
      ref={ref}
      boxShadow={"base"}
      rounded={"md"}
      backgroundColor={"white"}
      p={6}
      {...props}
    />
  );
});
