import { FalconFutureOrderFragment } from "../generated/FalconFutureOrderFragment";
import React, { useEffect, useState } from "react";
import { flattenEdges } from "../utils/edges";
import { Panel } from "./Panel";
import {
  Box,
  Button,
  Center,
  Heading,
  Input,
  SimpleGrid,
  Spinner,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@apollo/client";
import {
  ScheduledOrderSubmitFoodduckReview,
  ScheduledOrderSubmitFoodduckReviewVariables,
} from "../generated/ScheduledOrderSubmitFoodduckReview";
import {
  ScheduledOrderRequestCustomerConfirmation,
  ScheduledOrderRequestCustomerConfirmationVariables,
} from "../generated/ScheduledOrderRequestCustomerConfirmation";
import {
  ScheduledOrderMarkOrderCreated,
  ScheduledOrderMarkOrderCreatedVariables,
} from "../generated/ScheduledOrderMarkOrderCreated";
import { GetFalconFutureOrderQuery, ListLogEntryQuery } from "../api/queries";
import {
  GetFalconFutureOrder,
  GetFalconFutureOrderVariables,
} from "../generated/GetFalconFutureOrder";
import {
  FalconFutureOrderCustomerConfirmationStatus,
  FalconFutureOrderStatus,
} from "../generated/globalTypes";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { CloseIcon } from "@chakra-ui/icons";
import { formatDateMonthYear } from "../utils/datetime";
import { ArchiveButton } from "./FutureOrder/ArchiveButton";
import { FutureOrderLog } from "./FutureOrder/FutureOrderLog";
import { FutureOrderMessages } from "./FutureOrder/FutureOrderMessages";

const formatButter = (amount: number) => {
  return `${(amount / 1000).toFixed(2)} kg`;
};

const SUBMIT_FOODDUCK_REVIEW = gql`
  mutation ScheduledOrderSubmitFoodduckReview(
    $orderId: GlobalIDInput!
    $approved: Boolean!
  ) {
    scheduledOrderSubmitFoodduckReview(
      input: { orderId: $orderId, approved: $approved }
    ) {
      success
    }
  }
`;

const REQUEST_CUSTOMER_CONFIRMATION = gql`
  mutation ScheduledOrderRequestCustomerConfirmation(
    $orderId: GlobalIDInput!
    $phoneNumber: String!
  ) {
    scheduledOrderRequestCustomerConfirmation(
      input: { orderId: $orderId, phoneNumber: $phoneNumber }
    ) {
      success
    }
  }
`;

const MARK_ORDER_CREATED = gql`
  mutation ScheduledOrderMarkOrderCreated(
    $orderId: GlobalIDInput!
    $dlPrimeOrderNumber: String!
  ) {
    scheduledOrderMarkOrderCreated(
      input: { orderId: $orderId, dlprimeOrderNumber: $dlPrimeOrderNumber }
    ) {
      success
    }
  }
`;

interface FoodduckConfirmationProps {
  orderId: string;
  callback: () => void;
}
export const FoodduckConfirmation: React.FC<FoodduckConfirmationProps> = ({
  orderId,
  callback,
}) => {
  const [
    submitReview,
    // { loading: reviewLoading, error: reviewerror },
  ] = useMutation<
    ScheduledOrderSubmitFoodduckReview,
    ScheduledOrderSubmitFoodduckReviewVariables
  >(SUBMIT_FOODDUCK_REVIEW, {
    errorPolicy: "all",
    refetchQueries: [ListLogEntryQuery],
  });

  const handleReview = (approved: boolean) => {
    submitReview({
      variables: {
        orderId: orderId,
        approved: approved,
      },
    }).finally(() => {
      callback();
    });
  };

  return (
    <Box mt={6}>
      <Button colorScheme={"blue"} mr={2} onClick={() => handleReview(true)}>
        Confirm
      </Button>
      <Button colorScheme={"red"} onClick={() => handleReview(false)}>
        Reject
      </Button>
    </Box>
  );
};

interface CustomerConfirmationRequestProps {
  orderId: string;
  initialNumber: string | null;
  callback: () => void;
}
export const CustomerConfirmationRequest: React.FC<CustomerConfirmationRequestProps> = ({
  orderId,
  callback,
  initialNumber,
}) => {
  const [requestConfirmation, { loading, error }] = useMutation<
    ScheduledOrderRequestCustomerConfirmation,
    ScheduledOrderRequestCustomerConfirmationVariables
  >(REQUEST_CUSTOMER_CONFIRMATION, {
    errorPolicy: "all",
    refetchQueries: [ListLogEntryQuery],
  });
  const [phoneNumber, setPhoneNumber] = useState<string>(initialNumber || "");

  const handleSubmit = () => {
    requestConfirmation({
      variables: {
        orderId: orderId,
        phoneNumber: phoneNumber,
      },
    }).finally(() => {
      callback();
    });
  };

  return (
    <Box>
      <Text>Confirmation message will be sent to the number below</Text>
      <Box display={"flex"} flexDir={"row"} alignItems={"center"} mt={4}>
        <Input
          flex={5}
          placeholder={"+358"}
          disabled={loading}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          isInvalid={!!error}
        />
        <Button colorScheme={"blue"} onClick={handleSubmit} ml={3}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};

interface OrderCreatedFormProps {
  orderId: string;
  callback: () => void;
}
export const OrderCreatedForm: React.FC<OrderCreatedFormProps> = ({
  orderId,
  callback,
}) => {
  const [
    markOrderCreated,
    { loading: submissionLoading, error: submissionError },
  ] = useMutation<
    ScheduledOrderMarkOrderCreated,
    ScheduledOrderMarkOrderCreatedVariables
  >(MARK_ORDER_CREATED, {
    errorPolicy: "all",
    refetchQueries: [ListLogEntryQuery],
  });

  const [orderNumber, setOrderNumber] = useState<string>("");

  const handleSubmit = () => {
    markOrderCreated({
      variables: {
        orderId: orderId,
        dlPrimeOrderNumber: orderNumber,
      },
    }).finally(() => {
      callback();
    });
  };

  return (
    <Box display={"flex"} flexDir={"row"} alignItems={"center"} mt={4}>
      <Input
        flex={5}
        placeholder={"20"}
        disabled={submissionLoading}
        value={orderNumber}
        onChange={(e) => setOrderNumber(e.target.value)}
        isInvalid={!!submissionError}
      />
      <Button colorScheme={"blue"} onClick={handleSubmit} ml={3}>
        Submit
      </Button>
    </Box>
  );
};

const getStepIndex = (status: FalconFutureOrderStatus) => {
  if (status === FalconFutureOrderStatus.FOODDUCK_CONFIRMATION) {
    return 0;
  } else if (status === FalconFutureOrderStatus.CUSTOMER_CONFIRMATION) {
    return 1;
  } else if (status === FalconFutureOrderStatus.ORDER_HANDLING) {
    return 2;
  } else if (status === FalconFutureOrderStatus.FINISHED_SUCCESS) {
    return 4;
  } else if (status === FalconFutureOrderStatus.FINISHED_FAILURE) {
    return 4;
  }
  return 0;
};

interface FalconFutureOrderStepsProps {
  order: FalconFutureOrderFragment;
  callback: () => void;
}
const FalconFutureOrderSteps: React.FunctionComponent<FalconFutureOrderStepsProps> = ({
  order,
  callback,
}) => {
  const stepIndex = getStepIndex(order.status);
  const { activeStep, setStep } = useSteps({ initialStep: stepIndex });
  useEffect(() => {
    const currentStep = getStepIndex(order.status);
    if (currentStep !== activeStep) {
      setStep(currentStep);
    }
  }, [setStep, activeStep, order.status]);

  const reviewContent = (
    <Box>
      <Text>
        The order proposal is pending for Foodduck's confirmation. Please either
        confirm or reject it.
      </Text>
      <FoodduckConfirmation orderId={order.id} callback={callback} />
    </Box>
  );

  const customerConfirmationContent = (
    <Box>
      {order.customerConfirmationStatus ===
      FalconFutureOrderCustomerConfirmationStatus.REQUEST_NOT_SENT ? (
        <CustomerConfirmationRequest
          orderId={order.id}
          callback={callback}
          initialNumber={order.confirmationPhoneNumber}
        />
      ) : order.customerConfirmationStatus ===
        FalconFutureOrderCustomerConfirmationStatus.CUSTOM_REQUEST ? (
        <Text>
          The customer has replied with a custom message, see the messages tab
          for details.
        </Text>
      ) : (
        <Text>
          A message has been sent to the customer and the order is waiting for
          their confirmation.
        </Text>
      )}
    </Box>
  );

  const orderCreateContent = (
    <Box>
      <Text>
        Order approved by customer. Create a matching order on DL Prime and
        submit its ID below.
      </Text>
      <OrderCreatedForm orderId={order.id} callback={callback} />
    </Box>
  );

  const ArchiveStatus = () => (
    <Box mt={4}>
      <Text>
        The order is currently {order.isArchived ? "" : "not "}archived
      </Text>
      <ArchiveButton
        mt={4}
        orderData={order}
        callback={callback}
        color={"gray.800"}
        backgroundColor={"gray.200"}
      />
    </Box>
  );
  const doneContent = (
    <Box>
      <Text>The order has been successfully processed!</Text>
      <ArchiveStatus />
    </Box>
  );
  const FailContent = () => (
    <Box>
      <Text>The order has failed or been rejected.</Text>
      <ArchiveStatus />
    </Box>
  );

  const steps = [
    { label: "Review", content: reviewContent },
    { label: "Customer Review", content: customerConfirmationContent },
    { label: "Order Creation", content: orderCreateContent },
    { label: "Done", content: doneContent },
  ];
  const isFailure = order.status === FalconFutureOrderStatus.FINISHED_FAILURE;
  const isSuccess = order.status === FalconFutureOrderStatus.FINISHED_SUCCESS;
  const colorScheme = isFailure ? "red" : undefined;
  const checkIcon = isFailure ? CloseIcon : undefined;

  return (
    <>
      <Steps
        activeStep={activeStep}
        colorScheme={colorScheme}
        checkIcon={checkIcon}
      >
        {steps.map(({ label, content }) => (
          <Step label={label} key={label} pb={4}>
            {content}
          </Step>
        ))}
      </Steps>
      {isFailure && <FailContent />}
      {isSuccess ? doneContent : null}
    </>
  );
};

interface ProcessingTabProps {
  order: FalconFutureOrderFragment;
  refetchOrder: () => void;
}
const ProcessingTab: React.FC<ProcessingTabProps> = ({
  order,
  refetchOrder,
}) => {
  const lines = flattenEdges(order.lines);
  return (
    <>
      <SimpleGrid columns={2} mb={5} spacing={5}>
        <Panel>
          <Heading size={"lg"}>Billing Customer</Heading>
          <Text>{order.billingCustomer.name}</Text>
          <Text>{order.billingCustomer.customerCode}</Text>
          <Text>{order.billingCustomer.address}</Text>
          <Text>
            {order.billingCustomer.postalCode} {order.billingCustomer.city}
          </Text>
          <Text casing={"capitalize"}>{order.billingCustomer.country}</Text>
        </Panel>
        <Panel>
          <Heading size={"lg"}>Delivery Customer</Heading>
          <Text>{order.deliveryCustomer.name}</Text>
          <Text>{order.deliveryCustomer.customerCode}</Text>
          <Text>{order.deliveryCustomer.address}</Text>
          <Text>
            {order.deliveryCustomer.postalCode} {order.deliveryCustomer.city}
          </Text>
          <Text casing={"capitalize"}>{order.deliveryCustomer.country}</Text>
        </Panel>
      </SimpleGrid>
      <SimpleGrid columns={2} mb={5} spacing={5}>
        <Panel>
          <Heading fontSize="lg">Expiry date</Heading>
          <Text>{formatDateMonthYear(order.expiryDate)}</Text>
        </Panel>
        <Panel>
          <Heading fontSize="lg">Delivery date</Heading>
          <Text>{formatDateMonthYear(order.shipDate)}</Text>
        </Panel>
      </SimpleGrid>
      <Panel p={0} mb={5}>
        <Table>
          <Thead>
            <Tr>
              <Th>Product Code</Th>
              <Th>Description</Th>
              <Th>Quantity</Th>
              <Th>Total Amount</Th>
            </Tr>
          </Thead>
          <Tbody>
            {lines.map((line) => (
              <Tr key={line.id}>
                <Td>{line.product?.productCode}</Td>
                <Td>{line.product?.description}</Td>
                <Td>{line.quantity}</Td>
                <Td>
                  {formatButter(
                    line.product?.contentWeightGrams * line.quantity
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Panel>
      <Panel mb={5}>
        <FalconFutureOrderSteps order={order} callback={refetchOrder} />
      </Panel>
    </>
  );
};

interface FalconFutureOrderDetailsProps {
  order: FalconFutureOrderFragment;
}
export const FalconFutureOrderDetails: React.FunctionComponent<FalconFutureOrderDetailsProps> = ({
  order: { id: orderId },
}) => {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const {
    data: orderData,
    loading: orderLoading,
    refetch: refetchOrder,
  } = useQuery<GetFalconFutureOrder, GetFalconFutureOrderVariables>(
    GetFalconFutureOrderQuery,
    {
      fetchPolicy: "network-only",
      variables: {
        id: orderId,
      },
      pollInterval: 4000,
    }
  );
  const handleTabChange = (val: number) => {
    setSelectedTab(val);
  };

  const order = orderData?.getFalconFutureOrder;

  if (orderLoading || !order) {
    return (
      <Box pt={"23vh"} backgroundColor={"gray.100"} height={"100vh"}>
        <Center>
          <Spinner size={"xl"} margin={"0 auto"} />
        </Center>
      </Box>
    );
  }

  return (
    <Tabs index={selectedTab} onChange={handleTabChange}>
      <TabList>
        <Tab>Processing</Tab>
        <Tab>Log</Tab>
        <Tab>Messages</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>
          <ProcessingTab order={order} refetchOrder={refetchOrder} />
        </TabPanel>
        <TabPanel px={0}>
          <FutureOrderLog order={order} />
        </TabPanel>
        <TabPanel px={0}>
          <FutureOrderMessages order={order} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
