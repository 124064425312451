import React from "react";
import { useQuery } from "@apollo/client";
import { FalconFutureOrderFragment } from "../../generated/FalconFutureOrderFragment";
import { ListTwilioMessageQuery } from "../../api/queries";
import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { Panel } from "../Panel";
import { flattenEdges } from "../../utils/edges";
import { formatDateMonthYearHourMinute } from "../../utils/datetime";
import {
  ListTwilioMessage,
  ListTwilioMessageVariables,
} from "../../generated/ListTwilioMessage";
import { TwilioMessageFragment } from "../../generated/TwilioMessageFragment";
import { TwilioMessageTwilioDirection } from "../../generated/globalTypes";

function getDisplayDirection(message: TwilioMessageFragment): string {
  if (
    message.twilioDirection === TwilioMessageTwilioDirection.OUTBOUND_API ||
    message.twilioDirection === TwilioMessageTwilioDirection.OUTBOUND_CALL ||
    message.twilioDirection === TwilioMessageTwilioDirection.OUTBOUND_REPLY
  )
    return "Outbound";
  else if (message.twilioDirection === TwilioMessageTwilioDirection.INBOUND) {
    return "Inbound";
  }
  return "Unknown";
}

interface TwilioMessageListProps {
  topicId: string;
}
const TwilioMessageList: React.FC<TwilioMessageListProps> = ({ topicId }) => {
  const { data } = useQuery<ListTwilioMessage, ListTwilioMessageVariables>(
    ListTwilioMessageQuery,
    {
      variables: { topic: topicId },
      pollInterval: 4000,
    }
  );
  const flattened = flattenEdges(data?.listTwilioMessage);
  if (flattened.length === 0) {
    return (
      <Panel mb={5}>
        <Text>No messages found</Text>
      </Panel>
    );
  }
  return (
    <Panel mb={5}>
      <Table>
        <Thead>
          <Tr>
            <Th>Message</Th>
            <Th>Direction</Th>
            <Th>Time</Th>
          </Tr>
        </Thead>
        <Tbody>
          {flattened.map((entry) => (
            <Tr key={entry.id}>
              <Td style={{ whiteSpace: "pre-wrap" }}>{entry.twilioBody}</Td>
              <Td>{getDisplayDirection(entry)}</Td>
              <Td>{formatDateMonthYearHourMinute(entry.datetimeHandled)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Panel>
  );
};

interface FutureOrderMessagesProps {
  order: FalconFutureOrderFragment;
}
export const FutureOrderMessages: React.FC<FutureOrderMessagesProps> = ({
  order,
}) => {
  const topicId = order.twilioTopic?.id;
  if (!topicId) {
    return (
      <Panel mb={5}>
        <Text>The order has no messages attached yet</Text>
      </Panel>
    );
  }
  return <TwilioMessageList topicId={topicId} />;
};
