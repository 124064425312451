import React, { useState } from "react";

import {
  FalconCustomerQuery,
  ListDeliveryFalconCustomersQuery,
  ListFalconCustomersQuery,
} from "../../api/queries";
import { ListDeliveryFalconCustomers } from "../../generated/ListDeliveryFalconCustomers";
import { FilterTable } from "../../components/FilterTable";
import { FilterTableDisplayWithoutPanel } from "../../components/FilterTableDisplay";
import { FalconCustomerOrdering } from "../../generated/globalTypes";
import {
  ListFalconCustomers,
  ListFalconCustomers_listFalconCustomer_edges_node,
} from "../../generated/ListFalconCustomers";
import {
  URL_LOCATION_FUTURE,
  URL_LOCATION_ORDERS,
  URL_LOCATION_OVERVIEW,
} from "../../utils/urls";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  CustomerLayout,
  CustomerPageName,
} from "../../components/CustomerLayout";
import { useQuery } from "@apollo/client";
import {
  GetFalconCustomer,
  GetFalconCustomerVariables,
} from "../../generated/GetFalconCustomer";
import { Text, Switch as SwitchInput, Box, Divider } from "@chakra-ui/react";
import { FalconCustomerFragment } from "../../generated/FalconCustomerFragment";
import { LocationFutureOrders } from "./[id]/future";
import { CustomerDeliveryOrders } from "./[id]/orders";
import { Panel } from "../../components/Panel";

const LocationList: React.FunctionComponent = () => {
  const history = useHistory();
  const onSelectRow = async (
    row: ListFalconCustomers_listFalconCustomer_edges_node
  ) => {
    const href = URL_LOCATION_OVERVIEW.builder(row.id);
    await history.push(href);
  };

  const [locationsOnly, setLocationsOnly] = useState<boolean>(false);

  const mapResult = (
    res: ListDeliveryFalconCustomers | ListFalconCustomers
  ) => {
    if ("listDeliveryFalconCustomer" in res) {
      return res.listDeliveryFalconCustomer;
    } else {
      return res.listFalconCustomer;
    }
  };

  return (
    <Panel p={0}>
      <FilterTable
        pageSize={10}
        query={
          locationsOnly
            ? ListDeliveryFalconCustomersQuery
            : ListFalconCustomersQuery
        }
        variables={{}}
        mapQueryResult={mapResult}
        tableComponent={FilterTableDisplayWithoutPanel}
        onSelectRow={onSelectRow}
        columns={[
          {
            Header: "Name",
            accessor: (row) => row.name,
            orderingAsc: FalconCustomerOrdering.NAME_ASC,
            orderingDesc: FalconCustomerOrdering.NAME_DESC,
          },
          {
            Header: "Customer Code",
            accessor: (row) => row.customerCode,
            orderingAsc: FalconCustomerOrdering.CUSTOMER_CODE_ASC,
            orderingDesc: FalconCustomerOrdering.CUSTOMER_CODE_DESC,
          },
          {
            Header: "Postal Code",
            accessor: (row) => row.postalCode,
            orderingAsc: FalconCustomerOrdering.POSTAL_CODE_ASC,
            orderingDesc: FalconCustomerOrdering.POSTAL_CODE_DESC,
          },
          {
            Header: "City",
            accessor: (row) => row.city,
            orderingAsc: FalconCustomerOrdering.CITY_ASC,
            orderingDesc: FalconCustomerOrdering.CITY_DESC,
          },
        ]}
      >
        <Box p={2} mb={4}>
          <SwitchInput
            isChecked={locationsOnly}
            onChange={(e) => setLocationsOnly(e.target.checked)}
          >
            Show delivery locations only
          </SwitchInput>
        </Box>
        <Divider />
      </FilterTable>
    </Panel>
  );
};

const LocationIndex: React.FunctionComponent = () => {
  return (
    // <Layout title={"Locations"}>
    <LocationList />
    // </Layout>
  );
};

interface CustomerDetailProps {
  id: string;
}
const CustomerDetail: React.FC<CustomerDetailProps> = ({ id }) => {
  const { data, loading, error } = useQuery<
    GetFalconCustomer,
    GetFalconCustomerVariables
  >(FalconCustomerQuery, {
    variables: { id: id },
  });

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    console.log(error);
    return <Text>Error: {error.message}</Text>;
  }

  if (!data || !data.getFalconCustomer) {
    return <Text>Not found</Text>;
  }

  const customer: FalconCustomerFragment = data.getFalconCustomer;

  // TODO: Make activePage work
  return (
    <CustomerLayout customer={customer} activePage={CustomerPageName.OVERVIEW}>
      <Switch>
        <Route exact path={URL_LOCATION_OVERVIEW.pattern}>
          <Redirect to={URL_LOCATION_FUTURE.builder(customer.id)} />
        </Route>
        {/*<Route exact path={URL_LOCATION_OVERVIEW.pattern}>*/}
        {/*  <Location customer={customer} />*/}
        {/*</Route>*/}
        <Route path={URL_LOCATION_FUTURE.pattern}>
          <LocationFutureOrders customer={customer} />
        </Route>
        <Route path={URL_LOCATION_ORDERS.pattern}>
          <CustomerDeliveryOrders customer={customer} />
        </Route>
        {/*<Route path={URL_LOCATION_USAGE.pattern}>*/}
        {/*  <CustomerLocationsPanel customer={customer} />*/}
        {/*</Route>*/}
        {/*<Route path={URL_LOCATION_SETTINGS.pattern}>*/}
        {/*  <LocationSettings customer={customer} />*/}
        {/*</Route>*/}
      </Switch>
    </CustomerLayout>
  );
};

const LocationRoutes = () => {
  const { id } = useParams<{ id: string | undefined }>();
  return id ? <CustomerDetail id={id} /> : null;
};

const LocationRouter: React.FunctionComponent = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <LocationIndex />
      </Route>
      <Route path={URL_LOCATION_OVERVIEW.pattern}>
        <LocationRoutes />
      </Route>
    </Switch>
  );
};

export default LocationRouter;
